@import "src/styles/mixins/mixin.scss";

.invoicesContainer {
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  @include main-font;
  align-items: center;
  justify-content: center;
  display: flex;
}

.invoicesBlock {
  display: flex;
  flex-direction: column;
  width: calc(100% - 32px);
}

.topNav {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
}

.topNavBack {
  margin-left: -6px;

  .arrowLeft {
    @include icon-outline;
  }
}

.topNavText {
  p {
    margin: 0px;
    @include nav-font-size;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1px;
  }
}

.invoices {
  display: flex;
  flex-direction: column;
  padding-top: 23px;

  .invoice {
    display: flex;
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid var(--main-bg-color-secondary);

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
    }
  }

  a {
    color: var(--inverted-bg-color);
  }
}
.Button {
  font-family: "Roboto";
  cursor: pointer;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  padding: 16px 0px;
  border: none;
  color: var(--text-color-1);
  outline: none;
}

.fullWidth {
  width: 100%;
}

.primary {
  background-color: var(--button-bg);
  color: #fff;
}

.secondary {
  background-color: var(--button-bg-3);
  color: var(--text-color-1);
}

.order {
  background-color: #444348;
  color: #fff;
}

.clear {
  border: none;
  padding: 0;
  outline: none;
  background-color: inherit;
}

.link {
  padding: 0;
  border: none;
  outline: none;
  background: none;
  font: var(--font-m);
  color: var(--text-color);
  text-decoration: underline;
  letter-spacing: 0.25px;
}

.sorting {
  border-radius: 100px;
  padding: 0;
  background: var(--main-bg-color-secondary);
  color: #a1a1a1;
}

.disabled {
  opacity: 0.6;
}

.outline {
  background: none;
  border: 1px solid var(--inverted-bg-color);
}

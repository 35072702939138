@import "src/styles/mixins/mixin.scss";

.verefictaion-section__main {
  .verefictaion-icon_back {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .back-icon {
      @include icon-outline;
    }
  }

  .verefication_title {
    @include indent-top;

    p {
      margin: 0;
      padding: 0;
    }
    .verefication-title__auth {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: var(--inverted-bg-color);
    }
    .verefication-title__info {
      margin-top: 8px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
    }

    .phone-nubmer {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
    }
  }

  .verefictaion-section__input {
    padding-top: 26px;

    #error {
      @include error-text;
      margin-left: 11px;
    }

    .error_field {
      @include error-input-field;
    }

    .pass_input {
      text-indent: 0;
      width: 100%;
      text-indent: 14px;
      padding: 18px 0 17px 0;
      color: var(--text-color-1);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .forgot-pass_block {
    margin: 8px 5px 0 5px;
    .forgot-passwor-text {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: var(--text-color);
    }
  }

  .verefictaion-info__timer {
    margin: 72px 15px 0 15px;
    button {
      @include btn-continue;
      @include btn-width;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.apkpolicy-section {
  // height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  @include main-font;
  display: flex;
  .top-nav {
    padding-top: 10px;
    padding-left: 10px;
    .top-nav_back {
      .ArrowLeft {
        @include icon-outline;
        padding-bottom: 10px;
      }
    }
  }
  .top-nav_text {
    p {
      padding-left: 3px;
      margin: 0px;
      font-size: 5vw;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.1px;
    }
  }
  .main-text {
    padding-top: 40px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .paragraph {
      text-indent: 15px;
      margin-bottom: 0px;
    }
    .new-line {
      margin: 5px 0px 0px 0px;
    }
    .confirm {
      align-self: center;
      margin: 20px 20px;
      padding: 10px 15px 10px 15px;
      @include main-bg;
      border: none;
      border-radius: 5px;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

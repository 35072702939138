@import "src/styles/mixins/mixin.scss";

.delete-section {
  // height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  @include main-font;
  display: flex;

  .delete-block_btn {
    display: flex;
    position: fixed;
    bottom: 0;
    align-self: center;
    width: 91%;
    margin-top: auto;
    margin-bottom: 60px;
    .delete-btn {
      @include btn-width;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: var(--button-bg);
      color: #ffffff;
      border-radius: 12px;
      padding: 15px;
      border: none;

      p {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .top-nav {
    padding-top: 10px;
    padding-left: 10px;
    .top-nav_back {
      .ArrowLeft {
        @include icon-outline;
        padding-bottom: 10px;
      }
    }
    .top-nav_text {
      p {
        padding-left: 3px;
        margin: 0px;
        @include nav-font-size;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.1px;
      }
    }
  }

  .main-text-delete {
    padding-top: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    .paragraph {
      margin-bottom: 0px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
    }
    .confirm {
      align-self: center;
      margin: 20px 20px;
      padding: 10px 15px 10px 15px;
      @include main-bg;
      border: none;
      border-radius: 5px;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}

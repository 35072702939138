@import "src/styles/mixins/mixin.scss";

@media screen and (max-height: 690px) {
  .logo_icon {
    margin-top: -40px;
  }
}
//@import "~materialize-css/dist/css/materialize.min.css";

// .auth-section {
//     height: 100vh;
//     margin: 0 auto;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     @include main-font;
//     @include text-font-size;
//     .top-nav {
// 		display: flex;
// 		justify-content: center;
//         @include main-bg;
//         @include top-nav-paddings;
//         .top-nav-text {
//             text-transform: uppercase;
//             color: #fff;
//             text-align: left;
//             margin: 0px;
//             @include nav-font-size;
//         }
//     }
//     .registration-form {
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         padding: 0px 8px 0px 8px;
//         .error {
//             color: red;
//             @include error-font-size;
//             margin-bottom: 15px;
//         }
//         .phone-line {
//             @include phone-line;
//             .phone-tpl {
//                 @include phone-tpl;
//                 @include text-font-size;
//             }
//             .phone {
//                 @include phone;
//                 @include text-font-size;
//                 @include main-font;
//             }
//             // .pad {
//             //     @include pad;
//             // }
//         }
//         input {
//             border: none;
//             @include border-bot-col;
//             width: 100%;
//             margin: 0px 0px 5px 0px;
//         }
//         .input-text {
//             @include error-font-size;
// 			color: #212529;
// 			margin: 0px 0px 15px 0px;
//         }
//         .reg-password {
//             margin-bottom: 15px;
//         }
//         .reg-switcher{
//             margin-bottom: 15px;
//         }
//         .reg-button {
//             button {
//                 width: 100%;
//                 background-color: #000;
//                 color: #fff;
//                 text-transform: uppercase;
//                 @include nav-font-size;
//                 border: none;
//                 padding: 6px 0px 6px 0px;
//             }
//             a {
//                 color: #fff;
//                 text-decoration: none;
//             }
//         }
//     }
//     .bottom-nav {
//         .forgot-pass {
//             @include text-col;
//             text-transform: uppercase;
//             @include text-font-size;
//             text-align: center;
//             a {
//                 color: #000;
//                 text-decoration: none;
//             }
//         }
//         .no-acc {
//             text-align: center;
//             @include text-font-size;
//             a {
//                 color: #fea523;
//                 text-decoration: none;
//             }
//         }
//     }
// }

.auth-section {
  background-color: var(--main-bg-color);
  min-height: 100vh;
  .block_auth-logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--informing-bg-1);
    height: 460px;
    border-radius: 0px 0px 28px 28px;
    .logo_block {
      display: flex;
      margin-bottom: -36px;
      .logo_icon {
        width: 350px;
        height: 200px;
      }
    }
    .logo_text {
      display: flex;
      .logo-text_icon {
        width: 350px;
        height: 25px;
      }
    }
  }

  .block_auth-btn {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    margin: 67px 30px;

    .auth-btn {
      @include btn-width;
      align-self: center;
      padding: 0;
    }

    .block-register {
      display: flex;
      margin-top: 13px;
    }

    .auth-btn--login {
      background-color: var(--button-bg);
    }

    .auth-btn-login--link {
      display: flex;
      justify-content: center;
      padding: 16px 0;
      color: #ffffff;
    }

    .auth-btn--register {
      margin-top: 13px;
      background-color: var(--main-bg-color-secondary);
    }

    .auth-btn-register--link {
      display: flex;
      justify-content: center;
      padding: 16px 0;
      color: var(--text-color-1);
    }
  }

  .block_auth--title {
    color: var(--text-color-1);
    padding-top: 35px;
    padding-bottom: 45px;
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    .title_company {
      font-family: "Roboto";
      font-weight: 600;
      color: var(--text-color-1);
      text-decoration: underline;
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.history-section {
	min-height: 100vh;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	background-color: var(--main-bg-color-secondary);
	color: var(--inverted-bg-color);
	@include main-font;
	// .top-nav {
	//     @include main-bg;
	//     @include top-nav-paddings;
	//     display: flex;
	//     align-items: center;
	//     color: #fff;
	a {
		display: flex;
		margin-top: 10px;
		.ArrowLeft {
			@include icon-outline;
			padding-left: 10px;
			padding-bottom: 10px;
		}
	}
	.container-of-search {
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 10px;
		.buttons-container {
			display: flex;
			flex-direction: row;
			position: relative;
			justify-content: space-between;
			padding-top: 28px;
			.date-text-now {
				color: var(--text-color);
				font-size: 14px;
				margin-top: 2px;
				display: flex;
				left: 0;
				right: 0;
				position: absolute;
				flex-direction: row;
				justify-content: center;
			}
			.search-button {
				border: none;
				z-index: 0;
				background: transparent;
				padding: 0;
				path {
					stroke: var(--text-color);
				}
			}
			.sort-button {
				border: none;
				background: transparent;
				padding: 0;
				z-index: 0;
				path {
					fill: var(--text-color);
				}
			}
		}
		.search-main-container {
			background: var(--main-bg-inverted-color);
			border-radius: 12px;
			padding-left: 16px;
			margin-top: 10px;
			display: flex;
			flex-direction: row;
			height: 40px;
			input {
				height: 40px;
				border-radius: 12px;
				width: 86%;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				color: var(--modal-main-text);
				background: transparent;
				border: none;
				&::placeholder {
					color: var(--placeholder-color-1);
				}
				&:focus {
					border: none;
					outline: none;
				}
				&:hover {
					border: none;
					outline: none;
				}
			}
			path {
				stroke: var(--placeholder-color-1);
			}
		}
		.search-main-container-hidden {
			display: none;
		}
	}
	.top-nav-text {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 30px;
		/* identical to box height, or 125% */
		display: flex;
		align-items: center;
		letter-spacing: 0.1px;
		padding: 0px 16px;
		margin-top: 16px;
	}
	// }
	.no-history {
		align-self: center;
		margin-top: 40px;
	}
	.orders-list {
		list-style-type: none;
		padding: 0px 16px 80px 16px;
		background-color: var(--main-bg-color-secondary);
		.date-text {
			color: var(--text-color);
			font-size: 14px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-top: 16px;
			margin-bottom: 10px;
		}
		a {
			color: #000;
			text-decoration: none;
			.list-item {
				display: flex;
				flex-direction: column;
				margin-top: 8px;
				background-color: var(--main-bg-color);
				color: var(--inverted-bg-color);
				border-radius: 16px;
				padding: 24px 16px 24px 16px;
				// box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
				// padding: 4px 8px;
				// gap: 4px;
				p {
					margin: 5px 0px 5px 0px;
				}
				.payment-data {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					// margin-top: 10px;
					.sum {
						border-radius: 100px;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 700;
						font-size: 14px;
						background-color: var(--order-label-sum);
						color: var(--order-text-sum);
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 4px 8px;
						.coins-sum {
							display: flex;
							flex-direction: row;
							align-items: center;
						}
					}
					.distance-sum-main {
						border-radius: 100px;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 700;
						font-size: 14px;
						background-color: var(--inverted-bg-color);
						color: var(--main-bg-color);
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 4px 8px;
						.distance-sum {
							display: flex;
							flex-direction: row;
							align-items: center;
							path {
								fill: var(--main-bg-color);
								stroke: var(--main-bg-color);
							}
						}
					}
					.class-sum-main {
						border-radius: 100px;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 700;
						font-size: 14px;
						background-color: #1db960;
						color: var(--main-bg-color);
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 4px 10px;
						.class-sum {
							display: flex;
							flex-direction: row;
							align-items: center;
						}
					}
					.time-sum-main {
						border-radius: 100px;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 700;
						font-size: 14px;
						background-color: #eeeeee;
						color: black;
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 4px 10px;
						.time-sum {
							display: flex;
							flex-direction: row;
							align-items: center;
						}
					}
					.distance {
						border-radius: 100px;
					}
					.tariff {
						border-radius: 100px;
					}
					.time {
						border-radius: 100px;
						background-color: var(--order-label-time);
						color: var(--order-text-time);
						display: flex;
						flex-direction: row;
						align-items: center;
						padding: 4px 8px;
						gap: 4px;
					}
					// .payment-type {
					//     display: flex;
					//     align-items: center;
					//     .payment-icon {
					//         @include icon-size;
					//         margin-right: 10px;
					//         margin-left: 20px;
					//     }
					//     .money {
					//         color: green;
					//     }
					//     .card {
					//         color: #4c5937;
					//     }
					//     .bonus {
					//         color: #fea523;
					//         margin-left: 10px;
					//     }
					// }
					// .date {
					//     margin: 0px;
					//     color: blue;
					//     margin-right: 20px;
					// }
				}
				.line-items {
					display: flex;
					flex-direction: row;
					margin: 5px 0px 5px 0px;
					@include text-font-size;
					.col-block {
						width: 100%;
						.col-address {
							display: flex;
							flex-direction: row;
							.icon-type-pin {
								margin-right: 16px;
								margin-top: 5px;
								.path1 {
									fill: var(--inverted-bg-color);
								}
								.path2 {
									fill: var(--main-bg-color);
								}
								.map-circle-color {
									stroke: var(--order-detail-address-text);
									fill: var(--main-bg-color);
								}
							}
							.address-col-text {
								font-weight: 700;
								font-size: 18px;
								padding-bottom: 5px;
								margin: 0px 0px 3px 0px;
								border-bottom: 1.5px solid var(--border-color-1);
							}
							.address-col-text-without-border {
								font-weight: 700;
								font-size: 18px;
								padding-bottom: 0px;
								margin: 0px 0px 3px 0px;
								border: none;
							}
						}
					}
				}
				.sub-download {
					border-top: 1px solid grey;
					padding: 10px 5px 10px 5px;
					display: flex;
					justify-content: center;
					.download {
						background-color: #000;
						color: #fff;
						padding: 5px 25px 5px 25px;
						border: none;
						width: 100%;
					}
				}
			}
		}
	}
}

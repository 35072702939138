@import "src/styles/mixins/mixin.scss";
@import "src/styles/colors/colors.scss";

.orderHeader {
	display: flex;
	row-gap: 5px;
	width: 100%;
	margin: 0 auto;
	flex-direction: column;
	padding: 6px 16px;
	border-radius: 20px 20px 0 0;
	background: $black-900;
	background-size: 10%;
	position: relative;
}

.orderHeaderLoadingWrap {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 20px 20px 0 0;
	overflow: hidden;
}

.orderHeaderLoading {
	background-color: $green-200;
	height: 100%;
	width: 0;
	transition: all 1s linear;
}

.topHeaderElements {
	width: 100%;
	display: flex;
	justify-content: space-between;
	color: $white;
	z-index: 2;
}

.headerElement {
	display: flex;
	column-gap: 4px;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.headerLastElement {
	width: fit-content;
}

.topHeaderItem {
	display: flex;
	align-items: center;
	column-gap: 5px;
	font-size: 18px;
	width: 100%;

	svg {
		path {
			fill: $white;
		}
	}

	span {
		width: 100%;
		display: flex;
		flex-wrap: nowrap;
    white-space: nowrap;
	}

	&:not(:last-child)::after {
		background-color: $white;
		margin: 0 auto;
		width: 4px;
		height: 4px;
		border-radius: 50%;
		padding: 0 5px;
	}
}

.separatorWrap {
	width: 100%;
	padding: 0 5px;
}

.separatorRoundElement,
.separatorStraightElement {
	background-color: $white;
	margin: 0 auto;
}
.separatorRoundElement {
	width: 4px;
	height: 4px;
	border-radius: 50%;
}
.separatorStraightElement {
	height: 8px;
	width: 1px;
}

.orderPrice {
	display: flex;
	align-items: center;
	column-gap: 5px;
	z-index: 2;
}

.goldenItems {
	display: flex;
	align-items: center;
	column-gap: 5px;
	font-size: 24px;
	color: var(--order-label-sum);

	path {
		stroke: var(--order-label-sum);
		fill: var(--order-label-sum);
	}
}

.priceDetailWrap {
	display: flex;
	align-items: center;
	color: $white;
	column-gap: 5px;
}

.priceDetailItem {
	display: flex;
	align-items: center;
	column-gap: 5px;
	font-size: 18px;

	svg {
		path {
			fill: $white;
		}
		circle {
			stroke: $white;
		}
	}
}

.missedHeader {
	background-color: $red-500;
	.headerElement {
		opacity: 0.5;
		color: $white;
	}

	.topHeaderItem {
		svg {
			path {
				fill: $white;
			}
		}
	}

	.topHeaderElement:nth-child(1) {
		opacity: 1;

		.separatorWrap {
			opacity: 0.5;
		}
	}

	.priceDetailWrap {
		opacity: 0.5;

		.headerElement {
			opacity: 1;
		}
	}

	.goldenItems {
		opacity: 0.5;
		color: $white;

		path {
			stroke: $white;
			fill: $white;
		}
	}

	.separatorRoundElement,
	.separatorStraightElement {
		background-color: $white;
	}

	.priceDetailWrap {
		color: $white;
	}

	.priceDetailItem {
		svg {
			path {
				fill: $white;
			}
			circle {
				stroke: $white;
			}
		}
	}
}

@import "src/styles/mixins/mixin.scss";

.counterSelectorWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  font-size: 16px;
}

.fullInputWrap {
  display: flex;
  width: 100%;
}

.phoneInputWrap {
  display: flex;
  width: 100%;

  [class~="react-tel-input"] {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    width: 100%;
    gap: 5px;
  }

  [class~="form-control"] {
    border: none;
    padding: 0;
    background-color: var(--main-bg-color-secondary);
    width: 100%;
    padding: 18px 0 17px;
    border-radius: 12px;
    text-indent: 14px;
    color: var(--text-color-1);

    &:focus {
      background-color: var(--main-bg-color-secondary);
    }
  }

  [class~="flag-dropdown"] {
    position: relative;
    background-color: var(--main-bg-color-secondary);
    padding: 18px 5px 17px;
    border-radius: 12px;

    [class~="selected-flag"] {
      border: none;
      outline: none;
      box-shadow: none;

      &::before {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }

  [class~="country-list"] {
    [class~="search"] {
      padding: 6px;

      [class~="search-box"] {
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.error {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  color: red;
  @include error-font-size;
  @include main-font;
}

* {
  margin: 0;
  padding: 0;
}

.salaryModuleWrap {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin: 25px 16px 0px;
  background-color: var(--main-bg-color);
  border-radius: 16px;
  padding: 20px;

  * {
    box-sizing: border-box;
  }
}

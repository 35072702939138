@import "src/styles/mixins/mixin.scss";

.modalCardInfo {
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.1px;
    line-height: 30px;
    text-align: center;
    color: var(--text-color-1);
  }

  .cards-list {
    padding-top: 18px;
    .nav-card {
      padding: 15px 0;
      @include border;
    }

    .cards-section {
      display: flex;
      justify-content: space-around;
      .cards-items {
        display: flex;
        align-items: center;
        flex: 1;
        padding-left: 19px;

        .CreditCard {
          path {
            fill: var(--inverted-bg-color);
          }
          circle {
            stroke-width: 1px;
            stroke: var(--inverted-bg-color);
          }
        }

        .title_card {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
          color: var(--text-color);
        }

        .CreditCard {
          padding-right: 10px;
        }
      }
      .CheckIcon {
        padding-right: 20px;
        @include icon-outline;
      }
    }
    .add_card-block {
      display: flex;
      align-items: center;
      margin-top: 35px;
      padding-bottom: 40px;
      margin-left: 17px;
      .icon_plus {
        @include icon-outline;
        padding-right: 10px;
        path {
          stroke-width: 2.3px;
        }
      }
      .link_add_card {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: var(--text-color-1);
      }

      .add-card {
        background-color: inherit;
        border: none;
        outline: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: var(--text-color-1);
      }
    }
  }

  .selected-card {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #1db960;
    padding: 4px 8px;
    background: rgba(29, 185, 96, 0.2);
    border-radius: 100px;
    margin-left: 10px;
  }
}

.balanceSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 104px;
  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--text-color-1);
  }

  .balanceDriver {
    display: flex;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      padding-top: 6px;
      line-height: 16px;
      letter-spacing: 0.4px;
      padding-bottom: 15px;
      color: var(--text-color-1);
    }

    span {
      font-family: "Roboto";
      font-style: normal;
      letter-spacing: 0.1px;
      font-size: 12px;
      padding-top: 8px;
    }
  }

  .fieldSum {
    background-color: inherit;
    border: none;
    outline: none;
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 30px;
    letter-spacing: 0.1px;
    color: var(--inverted-bg-color);
  }

  .сommission {
    padding-top: 15px;
    color: var(--text-color-1);
    letter-spacing: 0.4px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .listWithdrawap {
    padding-top: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 16px;
    color: #0782f3;
  }

  .bonusesBlock {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .WalletIcon {
      path {
        fill: var(--inverted-bg-color);
        // stroke: var(--inverted-bg-color);
      }
      path:last-child {
        stroke: var(--inverted-bg-color);
        stroke-width: 1.5px;
      }
    }

    .paymentType {
      padding-top: 4px;

      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: var(--text-color-1);
      }
    }
  }

  .chooseCardSection {
    margin-top: 120px;
    display: flex;
    align-items: center;
    justify-self: center;
    align-self: center;
    .iconCard {
      display: flex;
      align-items: center;
      .CreditCard {
        path {
          fill: var(--inverted-bg-color);
          // stroke: var(--inverted-bg-color);
        }
        circle {
          stroke-width: 1px;
          stroke: var(--inverted-bg-color);
        }
      }
      .DownArrow {
        width: 16px;
        height: 24px;

        @include icon-outline;
      }
    }

    .chooseCard {
      padding-left: 9px;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: var(--text-color);
      }
    }
  }

  .no-cards-block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    p {
      color: #ee392e;
    }
  }
}

.nextBlockBtn {
  display: flex;
  align-self: center;
  width: 91%;
  margin-top: auto;
  margin-bottom: 60px;
  .nextBtn {
    @include btn-width;
    background-color: var(--button-bg);
    color: #ffffff;
    border-radius: 12px;
    border: none;
  }
  .btnDisabled {
    color: var(--button-text-color-disabled);
    background-color: var(--text-disable-color);
  }
}

@import "src/styles/mixins/mixin.scss";

.myreting-section {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  @include main-font;
  padding-top: 10px;
  a {
    display: flex;
    .ArrowLeft {
      @include icon-outline;
      padding-left: 10px;
      padding-bottom: 10px;
    }
  }
  .top-nav-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    padding: 0px 16px;
  }

  .driver-reting {
    display: flex;
    flex-direction: column;
    margin: 15px 16px 15px 16px;
    .profile-section {
      display: flex;
      flex-direction: column;
      background: var(--main-bg-color-secondary);
      border-radius: 28px;
      height: auto;
      width: 100%;
      padding: 32px 0 32px 0;
      align-items: center;
      .profile-img-block {
        display: flex;
        justify-content: center;
        .profile-img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
      }
      .profile-info {
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #212121;
        justify-content: center;
        .label-info {
          color: var(--inverted-bg-color);
          width: 100%;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1px;
          justify-content: center;
          margin-top: 8px;
        }
      }
      .rating-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        // padding: 7px 12px;
        background: var(--main-bg-color);
        border-radius: 100px;
        width: 189px;
        height: 38px;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 16px;
        .medal-icon {
          align-self: center;
          path {
            fill: #ffde17;
          }
        }
      }
    }
    .rating-and-bonus {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-top: 10px;
      gap: 8px;
      .rating-and-bonus-item {
        width: 50%;
        height: 106px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 16px 24px;
        gap: 2px;
        color: var(--text-color);
        border-radius: 16px;
        background-color: var(--main-bg-color-secondary);
        .label-rating-and-bonus {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: var(--inverted-bg-color);
          text-align: center;
          letter-spacing: 0.25px;
          height: 20px;
        }
        .value-rating-and-bonus {
          height: 30px;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: var(--inverted-bg-color);
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1px;
        }
        .ratings {
          position: relative;
          vertical-align: middle;
          display: inline-block;
          color: var(--inverted-bg-color);
          overflow: hidden;
        }
        .full-stars {
          position: absolute;
          left: 0;
          top: 0;
          white-space: nowrap;
          overflow: hidden;
          color: #ffde17;
        }
        .empty-stars:before,
        .full-stars:before {
          content: "\2605\2605\2605\2605\2605";
          font-size: 14pt;
        }
      }
    }
    // p {
    //     margin: 5px 0px 10px 0px;
    // }
    // .reting-text {
    //     @include text-font-size;
    //     width: 40%;
    // }
    // .reting-line {
    //     display: flex;
    //     flex-direction: row;
    //     .stars-list {
    //         list-style-type: none;
    //         padding: 0px;
    //         display: flex;
    //         flex-direction: row;
    //         align-self: center;
    //         margin: 0px;
    //         .star {
    //         //    @include medal-size;
    //             @include text-col;
    //         }
    //     }
    //     .reting-number {
    //         @include text-font-size;
    //     }
    // }
    .status-line {
      display: flex;
      flex-direction: row;
      .medal-icon {
        align-self: center;
        @include medal-size;
        @include text-col;
      }
      .reting-name {
        @include text-font-size;
        margin-left: 10px;
      }
    }
    .bonuses {
      display: flex;
      flex-direction: row;
      .bonus-col {
        @include text-col;
        @include text-font-size;
      }
    }
  }
  .my-comments {
    display: flex;
    justify-content: center;
    .note-button {
      padding: 5px 15px 5px 15px;
      background-color: #000;
      color: #fff;
      border: none;
      margin-top: 40px;
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.Wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  .block_title {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .back_icon {
      .arrowleft_icon {
        @include icon-outline;
      }
    }

    .title_text {
      color: var(--inverted-bg-color);
      padding-left: 15px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }
  }

  .block-search {
    margin-top: 25px;
    display: flex;
    align-items: center;
    position: relative;

    .search_input-block {
      flex-grow: 1;
      .search_input {
        color: var(--text-color-1);
        width: 100%;
        padding: 12px 0;
        text-indent: 14px;

        &::placeholder {
          color: var(--placeholder-color-1);
        }
      }
    }

    .search_icon-block {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon_search {
        @include icon-outline;
        path {
          stroke: var(--placeholder-color-1);
        }
      }
    }
  }

  .block-select_menu {
    padding: 1px;
    .nav-menu {
      padding: 0;
      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0 12px 0;
        border-bottom: 1px solid var(--border-color-1);
        list-style: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--inverted-bg-color);

        &:last-child {
          margin-bottom: 90px;
        }

        span {
          padding-left: 4px;
        }

        .check_icon {
          @include icon-outline;
          width: 18px;
          height: 18px;
        }
      }
    }
  }

	.block_btn {
		width: 100%;
		position: fixed;
		bottom: 0;
		display: flex;
		align-self: center;
		margin-bottom: 25px;
		button {
			width: 100%;
			margin: 0 30px;
			@include btn-continue;
		}
	}
}

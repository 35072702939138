.PageLayoutWrapper {
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  font-style: normal;
  margin: 0 auto;
  padding: 0 16px;
  min-height: calc(100vh - 104px);
}

@import "src/styles/mixins/mixin.scss";

.orderInfoWrap {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 5px;
  overflow-y: scroll;
  margin: 5px auto;
  transition: all 0.3s linear;

  * {
    box-sizing: border-box;
  }
}

.orderInfoItem {
  display: flex;
  width: 100%;
  background-color: var(--order-detail-address-bg);
  padding: 20px;
  color: var(--modal-main-text);
  align-items: center;
  border-radius: 20px;
  font-size: 14px;
  column-gap: 10px;
  justify-content: space-between;

  path {
    fill: var(--inverted-bg-color);
  }

  img {
    width: 100%;
    max-width: 24px;
  }

  .orderInfoItemInner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    span:nth-child(2) {
      font-weight: 700;
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.fullOrderWrap {
  display: flex;
  flex-direction: column;
  background-color: var(--order-main-bg-color);
  position: fixed;
  top: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  transform: translateX(100%);
  transition: all 0.3s linear;
  overflow: auto;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &Open {
    opacity: 1;
    transform: translateX(0);
    z-index: 1300;
  }
}

.fullOrder {
  margin-top: 10px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;

  h1 {
    font-size: 24px;
  }

  .clientPhoto {
    border-radius: 50%;
    width: 100%;
    max-width: 48px;
  }
}

.arrowLeft {
  transform: rotate(180deg);
  display: flex;
  width: 24px;
  min-height: 24px;

  path {
    stroke: var(--inverted-bg-color);
  }
}

.faPhone {
  path {
    fill: none;
  }
}

@import "src/styles/mixins/mixin.scss";

.languageSelector {
	position: relative;
	background-color: var(--main-bg-color);
	z-index: 10000;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.topNav {
  @include icon-outline;
}
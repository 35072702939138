@import "src/styles/mixins/mixin.scss";

.edit-profile-section {
	background-color: var(--main-bg-color);
	min-height: 100vh;
	.container {
		@include container;

		.hidden_block {
			display: none;
		}

		.edit-profile-section__main {
			display: flex;
			flex-direction: column;

			.navigation-block {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 10px;

				.icon-nav {
					@include icon-outline;
				}
			}

			.title-info_block {
				display: flex;
				justify-content: space-between;
				margin: 10px 0;
				h1 {
					max-width: 165px;
					color: var(--text-color-1);
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 24px;
					line-height: 30px;
					letter-spacing: 0.1px;
				}
				.profile-img-block {
					display: flex;
					justify-content: center;
					position: relative;
					.profile-img {
						width: 70px;
						height: 70px;
						border-radius: 50%;
						object-fit: cover;
					}
					.camera-icon_block {
						position: absolute;
						background: #fff;
						width: 28px;
						/* z-index: 100000; */
						height: 27px;
						bottom: 0;
						right: -8px;
						border-radius: 100px;
						display: flex;
						/* justify-content: center; */
						align-items: center;
						align-items: center;
						.camera_icon {
							padding-right: 10px;
							width: 23px;
							height: 22px;
							position: absolute;
							left: 3px;
							top: 2px;
						}
					}
				}
			}

			.edit-profile_block {
				display: flex;
				flex-direction: column;
				margin-top: 9px;

				#error {
					@include error-text;
				}

				.error_field {
					@include error-input-field;
				}

				input {
					color: var(--text-color-1);
					width: 100%;
					padding: 18px 0;
					font-size: 16px;
				}
				&::placeholder {
					color: var(--placeholder-color-1);
				}
			}

			p {
				margin: 0;
				padding: 0 11px;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 24px;
				color: var(--text-color);
			}

			.phone-block {
				margin-top: 9px;

				.phone-input {
					font-size: 14px;
				}
			}

			.confirm_block {
				width: 90%;
				margin-top: auto;
				display: flex;
				align-self: center;
				margin-bottom: 60px;
				.confirm_btn {
					@include btn-width;
					@include btn-continue;
					margin-top: 35px;
				}

				.btn_disabled {
					color: var(--button-text-color-disabled);
					background-color: var(--text-disable-color);
				}
			}
		}
		.cities-wrap {
			z-index: 20;
			background-color: var(--main-bg-color);
			width: 100%;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			display: flex;
			flex-direction: column;
		}

		.cities-select-styles {
			height: 100%;
			min-height: auto;
		}
	}
}

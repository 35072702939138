@import "src/styles/mixins/mixin.scss";

.penalties-section {
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  padding-top: 10px;

  a {
    display: flex;
    .ArrowLeft {
      @include icon-outline;
      padding-left: 10px;
      padding-bottom: 10px;
    }
  }
  .top-nav-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
    letter-spacing: 0.1px;
    padding: 10px 16px 16px 16px;
  }

  .nav-line {
    display: flex;
    flex-direction: row;
    margin: 20px 0px 10px 0px;
    justify-content: space-around;
    .nav-btn {
      width: 50%;
      border-radius: 0px 100px 100px 0px;
      margin-right: 8px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--order-detail-address-text);
      background-color: var(--order-detail-address-bg);
      &-left {
        @extend .nav-btn;
        border-radius: 100px 0px 0px 100px;
        margin-left: 8px;
        margin-right: 0px;
      }
    }
    .active {
      color: var(--main-bg-color);
      background-color: var(--inverted-bg-color);
    }
  }
  .penalties-list {
    list-style-type: none;
    margin: 0px;
    padding: 16px 16px 80px 16px;
    .penalties-list-block {
      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: var(--text-color-1);
      }

      .penalties-item {
        @include border;
        padding: 10px 0;

        &:last-child {
          padding-bottom: 25px;
          border: none;
        }

        .no_read {
          background-color: var(--warning-bg);
        }

        .penalties-block {
          border-radius: 16px;
          position: relative;

          .no_read__point {
            position: absolute;
            width: 10px;
            height: 10px;
            background: #ee392e;
            border-radius: 100px;
            right: 10px;
            top: 10px;
          }
          .block-reason-content {
            // display: flex;
            // align-items: flex-start;
            padding: 18px 0 0 18px;
            .block-reason {
              display: flex;
              align-items: flex-start;

              .warning-fine {
                path {
                  fill: var(--inverted-bg-color);
                }
              }
              .test {
                .main-penalties {
                  // padding: 19px 0 6px 16px;
                  padding-left: 8px;
                  text-align: left;
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 17px;
                  line-height: 19px;
                  letter-spacing: 0.15px;
                  color: var(--inverted-bg-color);
                }
                .title-penalties {
                  padding: 5px 85px 16px 8px;
                  text-align: left;
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.25px;
                  color: var(--text-color-4);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .block-title {
            }
          }
        }
      }
    }
  }
}

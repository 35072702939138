@import "src/styles/mixins/mixin.scss";
@import "src/styles/colors/colors.scss";

.finalOrderMenuWrap {
  display: flex;
  width: 100%;
  background-color: var(--order-detail-address-bg);
  padding: 15px;
  color: var(--modal-main-text);
  align-items: center;
  border-radius: 20px;
  font-size: 14px;
  column-gap: 10px;
  justify-content: space-between;
  flex-direction: column;

  h2 {
    font-size: 24px;
  }
}

.finalPrice {
  margin-top: 10px;
  font-size: 33px;
  font-weight: bold;
}

.finalOrderPriceBonuses {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  align-items: center;
  column-gap: 8px;
  opacity: 0.6;
  font-size: 20px;
}

.finalOrderPriceBonusesItem {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  width: 100%;
}

.finalOrderPriceBonusesInner {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  span:nth-child(2) {
    font-weight: 700;
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: $gray-300;
  margin: 10px 0;
}

.finalOrderBonusesDetails {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 100%;
  max-width: 300px;
  opacity: 0.6;

  li {
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    span:nth-child(2) {
      font-weight: 700;
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.addCard {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: -4px;
  border: none;
  background: none;
  .icon {
    @include icon-outline;
    padding-right: 10px;
    path {
      stroke-width: 2.3px;
    }
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: var(--text-color-1);
  }
}


.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);

  padding: 0 16px 6px;

  .content {
    .icons {
      display: flex;
      justify-content: space-between;
      .icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        path {
          stroke: var(--inverted-bg-color);
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.transactions-section {
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include main-font;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  @include container;
  // .top-nav {
  //     display: flex;
  //     align-items: center;
  //     @include main-bg;
  //     @include top-nav-paddings;
  //     color: #fff;
  //     .FaArrowLeft {
  //         padding-left: 20px;
  //         color: #fff;
  //     }
  //     .top-nav-text {
  //         text-align: left;
  //         margin: 0px;
  //         @include pad-left;
  //         @include nav-font-size;
  //     }
  // }

  .top-nav {
    padding-bottom: 12px;
    margin-top: 10px;
  }
  a {
    display: flex;
    .ArrowLeft {
      @include icon-outline;
      padding-bottom: 10px;
    }
  }
  .top-nav-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    padding-bottom: 12px;
  }
  .no-transactions {
    align-self: center;
    margin-top: 40px;
  }

  .transactions-list {
    list-style-type: none;
    padding: 0px;

    .transaction-list-block {
      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 12px;
        color: var(--text-color);
      }

      .transaction-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include border;
        padding: 15px 0;

        &:last-child {
          padding-bottom: 40px;
          border: none;
        }

        &:first-child {
          background-color: red;
        }

        .info-block {
          display: flex;
          align-items: center;

          .ArrowInfo {
            min-width: 40px;
            min-height: 40px;

            path {
              stroke-width: 1px;
            }
          }

          .info-title {
            padding: 0 15px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.5px;
          }

          .status_info {
            padding-left: 15px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: var(--text-color);
          }
        }

        .current-amount {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
          padding-right: 11px;
        }

        .send_money {
          color: #1db960;
        }

        .get_money {
          color: #ee392e;
        }
      }
    }
    // .transaction-list-item {
    //     display: flex;
    //     flex-direction: column;
    //     border: 2px solid var(--inverted-bg-color);
    //     border-radius: 5px;
    //     padding: 5px 10px 5px 10px;
    //     margin-bottom: 10px;
    //     p {
    //         margin: 0px;
    //     }
    //     .transaction-line {
    //         display: flex;
    //         flex-direction: row;
    //         justify-content: space-between;
    //         margin: 5px 0px 5px 0px;
    //         .line {
    //             display: flex;
    //             flex-direction: row;
    //             .label {
    //                 margin-right: 10px;
    //                 // @include text-col;
    //             }
    //         }
    //     }
    // }
  }
}

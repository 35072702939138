.order-pref-container {
  margin: 0 11px;
  padding: 20px;

  .close-block {
    display: flex;
    justify-content: end;

    .close-icon {
      path {
        stroke: var(--inverted-bg-color);
      }
    }
  }

  h1 {
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.1px;
    text-align: center;
    color: var(--inverted-bg-color);
    padding-bottom: 40px;
  }

  .prefs-block {
    h2 {
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.5px;
      padding-bottom: 10px;
      color: var(--inverted-bg-color);
    }

    .prefs-list {
      padding: 0;
      margin-top: 10px;
      li {
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0 12px 0;
        border-bottom: 1px solid var(--border-color-1);
        list-style: none;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: var(--inverted-bg-color);
      }
      .check_icon {
        path {
          stroke: var(--inverted-bg-color);
        }
      }
    }
  }

  .confirm_block {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .confirm_btn {
      width: 100%;
      background-color: var(--button-bg);
      color: #ffffff;
    }

    .btn_disabled {
      color: var(--button-text-color-disabled);
      background-color: var(--text-disable-color);
    }
  }
}

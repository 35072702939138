@import "src/styles/mixins/mixin.scss";

.icon {
  path {
    stroke: var(--inverted-bg-color);
  }
}

.iconWithoutStroke {
  path {
    stroke: none;
    fill: var(--inverted-bg-color);
  }
}

.cards {
  margin-top: 48px;

  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding-bottom: 11px;
  }
}

.operationsBtnBlock {
  width: 100%;
  margin-top: auto;
  margin-bottom: 30px;
  .operationsBtn {
    @include btn-width;
    background-color: var(--button-bg);
    color: #ffffff;
    border-radius: 12px;
    border: none;
  }
  .btnDisabled {
    color: var(--button-text-color-disabled);
    background-color: var(--text-disable-color);
  }
}

.paymentsItemsWrap {
  display: flex;
  margin-top: 12px;
  width: 100%;
  justify-content: space-between;

  align-items: center;

  li {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 15px;
    background-color: var(--main-bg-color-secondary);
    color: var(--inverted-bg-color);
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    transition: all 0.3s ease;
  }

  .activePayment {
    background-color: var(--order-label-sum);
    color: var(--order-text-sum);
  }
}

@media only screen and (max-width: 380px) {
  .paymentsItemsWrap {
    li {
      padding: 5px 8px;
    }
  }
}
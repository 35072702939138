@import "src/styles/mixins/mixin.scss";

.newModalWrap {
  background: rgba(33, 33, 33, 0.7);
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1005;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

}

.newModalContent {
  max-width: 500px;
  background-color: var(--main-bg-color);
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 20px;

  p {
    font-size: 24px;
    font-weight: 700;
    color: var(--inverted-bg-color);
  }
}

.newModalTopWrap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.newModalButtons {
  width: 100%;
  margin-top: 24px;
  display: flex;
  max-width: 300px;
  column-gap: 8px;
}

.newModalConfirmButton,
.newModalCancelButton {
  width: 100%;
  font-size: 16px;
  padding: 20px;
  border-radius: 10px;
  font-weight: 700;
  border: 1px solid var(--inverted-bg-color);
}

.newModalConfirmButton {
  background-color: transparent;
  color: var(--inverted-bg-color);
}

.newModalCancelButton {
  background-color: var(--inverted-bg-color);
  color: var(--main-bg-color);
}

.SmsInput {
  padding: 0; 
  margin: 116px 0 72px 0; 
  display: flex;
  justify-content: center;

  input {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: var(--inverted-bg-color);
    text-align: center;
    padding: 14px 0;
    width: 60px;
    border: none;
    outline: none;
    margin: 0 6px;
    background: inherit;
    border-bottom: 2px solid var(--inverted-bg-color);
  }
}
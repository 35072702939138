@import "src/styles/mixins/mixin.scss";

.modal-operation {
  @include modal;
  z-index: 10001;

  .modaloperation-section {
    @include modal-active;
    border: none;
    position: absolute;
    border-radius: 28px;
    bottom: 16px;
    width: 95%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
    }

    .alert-text {
      text-align: center;
      padding-top: 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: var(--modal-main-text);
      padding-bottom: 24px;
    }

    .action-block {
      @include border;
      display: flex;
      align-items: center;

      button {
        border: none;
        background: inherit;
        outline: none;
        display: flex;
      }
      .border_bottom {
        padding: 19px 0;
        color: var(--text-color-1);
      }
      .btn-modal_handler {
        padding: 0 20px 0 17px;
        .action_handler-one {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
        }
        .action_handler-two {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
        }
      }

      &:last-child {
        padding-bottom: 40px;
      }
    }
  }

  .icon-modal_active {
    margin-top: 4px;
    path {
      stroke: var(--inverted-bg-color);
    }
  }

  .bonuses-icon {
    path {
      stroke: none;
      fill: var(--inverted-bg-color);
    }
  }
}

$gap: 8px;
$paddingVertical: 16px;
$itemHeight: 130px;
$borderRadius: 15px;

.balanceBonusesBlock {
  gap: $gap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $paddingVertical 0;

  .item,
  .itemBonus {
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.1px;
    display: flex;
    width: 100%;
    height: $itemHeight;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: $borderRadius;

    .iconLabel {
      width: 100%;
      padding-left: 31px;
      padding-bottom: 14px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .CrownIcon {
        margin-right: 10px;
        path {
          fill: var(--inverted-bg-color);
        }
      }
    }

    .value {
      width: 100%;
      padding-left: 31px;

      .currency {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 30px;
        letter-spacing: 0.1px;
      }
    }
  }

  .item {
    background-color: var(--informing-bg-1);
    color: var(--order-text-sum);
  }

  .itemBonus {
    background-color: var(--main-bg-color-secondary);
    color: var(--order-label-distance);
  }
}

@import "src/styles/mixins/mixin.scss";

.ErrorPageWrapper {
	background: var(--main-bg-color);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100vh;

	.header {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100vh;

		h1 {
			font: var(--font-xl);
			color: var(--primary-color);
			font-weight: var(--font-weight-dark);
			letter-spacing: 0.5px;
			font-size: 33px;
			padding-bottom: 5px;
		}

		h4 {
			font: var(--font-l);
			color: var(--secondary-color);
			font-weight: var(--font-weight-regular);
			letter-spacing: 0.5px;
			padding-bottom: 15px;
		}

		.accordionError {
			margin: 0 20px;
		}
	}

	// .error-btn--block {
	//   margin-top: auto;
	//   width: 90%;
	//   display: flex;
	//   align-items: center;
	//   margin-bottom: 40px;
	// }

	.error-btn--block {
		width: 100%;
		margin-top: auto;
		margin-bottom: 40px;
		display: flex;
		justify-content: center;

		.back_btn {
			@include btn-continue;
			margin-top: 30px;
			width: 90%;
		}
	}
}

@import "src/styles/mixins/mixin.scss";

.verefictaion-section__main {
  .verefictaion-icon_back {
    display: flex;
    align-items: center;

    .back-icon {
      @include icon-outline;
    }
  }

  .verefication_title {
    margin-top: 10px;

    p {
      margin: 0;
      padding: 0;
    }
    .verefication-title__auth {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: var(--inverted-bg-color);
    }
    .verefication-title__info {
      margin-top: 8px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
    }

    .phone-nubmer {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
    }
  }

  .verefictaion-info__timer {
    text-align: center;
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--text-color-4);
    }
  }

  .confirm_block {
    margin: 72px 15px 0 15px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    .confirm_btn {
      @include btn-width;
      @include btn-continue;
    }

    .btn_disabled {
      color: var(--button-text-color-disabled);
      background-color: var(--text-disable-color);
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.orders-section {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include main-font;

  .uppercase {
    text-transform: uppercase;
  }
  .top-nav {
    display: flex;
    @include main-bg;
    @include top-nav-paddings;
    color: #fff;
    justify-content: space-around;
    align-items: center;
    .menu-icon {
      /*    width: 20px;
            height: 30px;*/
      @include menu-icon-size;
    }
    p {
      margin-bottom: 0px;
    }
    .top-nav-text {
      white-space: nowrap;
      text-transform: uppercase;
      color: #fff;
      align-self: center;
      margin: 0px;
      @include nav-font-size;
    }
  }
  #map {
    position: inherit;
    height: 100%;
    width: 100%;
  }
  .work-radius {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    position: fixed;
    top: 40px;
    z-index: 1000;
  }

  .controls-block {
    display: flex;
    width: 91%;
    position: fixed;
    bottom: 166px;
    z-index: 10;
  }

  .work-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91%;
    height: 56px;
    position: fixed;
    bottom: 94px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.5px;
    border: 0;
    z-index: 900;
  }
  .online {
    background-color: var(--button-stop-work-bg);
    color: var(--button-stop-work-text-color);
  }
  .offline {
    background-color: var(--button-start-work-bg);
    color: var(--button-start-work-text-color);
  }

  .disabled-btn {
    background-color: var(--button-color-disabled);
    color: var(--inverted-bg-color);
  }

  .status-label-wrap {
    top: 0;
    position: absolute;
  }

  .status-label {
    position: relative;
    margin-top: 18px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    justify-content: center;
    color: var(--button-start-work-text-color);
    z-index: 20;
  }
  .workon {
    background-color: var(--informing-bg-2);
  }
  .workoff {
    background-color: var(--text-disable-color);
  }
  /*  .map-section {
        height: 100%;
    }*/
  .orders-list {
    list-style-type: none;
    padding: 0px 0px 0px 0px;
    margin: 0px;
    padding-right: 10px;
    @include text-font-size;
    .order-item {
      .address-list {
        list-style-type: none;
        margin: 0px;
        padding: 15px 5px 10px 10px;
        .address {
          p {
            margin: 0px 0px 3px 0px;
          }
          .FaAngleDown {
            margin-left: 50px;
            color: #fea523;
          }
        }
      }
      .note {
        padding: 0px 5px 5px 10px;
        p {
          margin: 0px;
        }
      }
      .order-details {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #000;
        margin-top: 20px;
        p {
          margin: 0px;
        }
        .range {
          color: #fff;
        }
        .reg-button {
          display: flex;
          justify-content: center;
          button {
            background-color: #000;
            color: #fff;
            font-size: 16px;
            border: 2px solid green;
            border-radius: 15px;
            margin: 5px 0px 5px 0px;
            padding: 6px 6px 6px 6px;
          }
          a {
            color: #fff;
            text-decoration: none;
          }
        }
        .cost {
          color: green;
          @include nav-font-size;
        }
      }
      .line {
        height: 5px;
        background-color: #000;
      }
    }
  }
}

.note-text {
  border: 1px solid #fea523;
  padding: 10px;
}

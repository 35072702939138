@import "src/styles/mixins/mixin.scss";

.edit-car_section {
  background-color: var(--main-bg-color);

  .container {
    @include container;
    // min-height: calc(100vh - 26px);
    min-height: 100vh;
    .navigation-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;

      .icon-nav {
        @include icon-outline;
      }
    }

    .title-info_block {
      margin-top: 11px;
      h1 {
        color: var(--text-color-1);
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.1px;
      }
    }

    .car-info {
      margin-top: 12px;
      padding: 0 16px;
      background-color: var(--main-bg-color-secondary);
      border-radius: 28px;
      .border_btm-active {
        border-bottom: 1px solid var(--main-bg-inverted-color);
      }
      .border_btm {
        border-bottom: 1px solid var(--main-bg-color-secondary);
      }
      .car-info_block {
        padding-top: 19px;
        padding-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: var(--inverted-bg-color);
        }

        .govnumber_car {
          border: 1px solid var(--inverted-bg-color);
          border-radius: 6px;
          padding: 2px 10px;
        }

        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
          color: var(--inverted-bg-color);
        }

        &:last-child {
          padding-bottom: 25px;
        }
      }
    }

    .prefs-block {
      padding-top: 44px;
      p {
        // padding-bottom: 24px;
        color: var(--inverted-bg-color);
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }

      .prefs-list {
        padding: 0;
        margin-top: 10px;
        li {
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 20px 12px 0;
          border-bottom: 1px solid var(--border-color-1);
          list-style: none;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: var(--inverted-bg-color);

          .check_icon {
            @include icon-outline;
          }
        }
      }
    }

    .update-btn_block {
      width: 100%;
      margin-top: auto;
      margin-bottom: 40px;

      .update_btn {
        @include btn-continue;
        margin-top: 10px;

        width: 100%;
        // background-color: inherit;
        // color: #ee392e;
        // font-family: "Roboto";
        // font-style: normal;
        // font-weight: 400;
        // font-size: 16px;
        // line-height: 19px;
        // letter-spacing: 0.5px;
      }
    }

    // .update-btn_block {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin-top: 290px;
    //   padding-bottom: 30px;
    //   .update_btn {
    //     width: 94%;
    //     @include btn-continue;
    //   }
    // }
  }
}

.modalAlert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 9px;
  padding: 10px 0 20px 0;
}

.closeBtn {
  padding-top: 20px;
}

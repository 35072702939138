@import "src/styles/mixins/mixin.scss";

.guide-section {
  min-height: 100vh;
  background-color: var(--main-bg-color);

  .container {
    @include container;

    .guide-section__main {
      .block_title {
        display: flex;
        align-items: center;
        padding-top: 10px;

        .back_icon {
          // font-size: 22px;
          .arrowleft_icon {
            color: var(--inverted-bg-color);
            font-size: 20px;
          }
        }
      }

      .block-named {
        padding-top: 10px;
        h1 {
          color: var(--inverted-bg-color);
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          letter-spacing: 0.1px;
        }
      }

      .list-guide {
        .block-guide {
          margin-top: 20px;
          h2 {
            color: var(--inverted-bg-color);
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.5px;
            padding-bottom: 8px;
          }

          p {
            color: var(--placeholder-color-1);
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.5px;
            padding-bottom: 6px;
          }

          img {
            max-width: 100%;
          }

          &:last-child {
            padding-bottom: 62px;
          }
        }
      }
    }
  }
}

@import "src/styles/mixins/mixin.scss";

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1.5) {
	.row {
		// height: 100vh;
	}
}

.cars-section {
	background-color: var(--main-bg-color);
	.container {
		@include container;
		min-height: 100vh;
		.prefs-block {
			padding-top: 44px;
			padding-bottom: 20px;

			p {
				color: var(--inverted-bg-color);
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 24px;
			}

			.prefs-list {
				padding: 0;
				margin-top: 10px;
				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 16px 20px 12px 0;
					border-bottom: 1px solid var(--border-color-1);
					list-style: none;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					color: var(--inverted-bg-color);

					.check_icon {
						@include icon-outline;
					}
				}
			}
		}

		.btn-action_block {
			.active_auto {
				padding: 0;
			}
			.change-auto_block {
				margin-bottom: 15px;
				.change_btn {
					border: 1px solid var(--inverted-bg-color);
					background-color: inherit;
					color: var(--informing-bg-4);
					width: 100%;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					letter-spacing: 0.5px;
				}
			}

			.inspection-auto_block {
				margin-bottom: 15px;
				.inspection_btn {
					border: 1px solid var(--inverted-bg-color);
					background-color: inherit;
					color: var(--informing-bg-4);
					width: 100%;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					letter-spacing: 0.5px;
				}
			}
		}

		.remove-auto_block {
			width: 100%;
			margin-top: auto;
			margin-bottom: 20px;

			.remove_btn {
				@include btn-width;
				border: 1px solid var(--inverted-bg-color);
				background-color: inherit;
				color: #ee392e;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 0.5px;
			}
		}

		.car-info {
			margin-top: 12px;
			padding: 0 16px;
			background-color: var(--main-bg-color-secondary);
			border-radius: 28px;
			.border_btm-active {
				border-bottom: 1px solid var(--main-bg-inverted-color);
			}
			.border_btm {
				border-bottom: 1px solid var(--main-bg-color-secondary);
			}
			.car-info_block {
				padding-top: 19px;
				padding-bottom: 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				h2 {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.5px;
					color: var(--inverted-bg-color);
				}

				.govnumber_car {
					border: 1px solid var(--inverted-bg-color);
					border-radius: 6px;
					padding: 2px 10px;
				}

				p {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					letter-spacing: 0.5px;
					color: var(--inverted-bg-color);
				}

				&:last-child {
					padding-bottom: 25px;
				}
			}
		}

		.default-auto_block {
			margin-bottom: 15px;
			.defaultcar_btn {
				border: 1px solid var(--inverted-bg-color);
				background-color: inherit;
				color: var(--informing-bg-4);
				width: 100%;
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 0.5px;
			}
		}

		.block_list {
			padding: 8px 0;
			height: 88px;
			border-bottom: 1px solid var(--main-bg-color-secondary);
			display: flex;
			overflow: hidden;
			position: relative;

			.car-need-inspection {
				background-color: var(--bg-inspectation-car);
			}

			.active_car {
				height: 86px;
				background-color: var(--main-bg-color-secondary);
				border-radius: 16px;
			}

			.nav-list_cars {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-radius: 16px;
				flex: 1 0 100%;

				.main-current_block {
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex: 1 0 100%;
					.radio-btn_block {
						padding-left: 6px;
						.radio-check_block {
							& .MuiFormGroup-root {
								flex-direction: row;
								align-items: center;
							}
							& .MuiFormControlLabel-root {
								margin: 0;
							}

							& .MuiRadio-root {
								color: var(--inverted-bg-color);
							}
						}
					}

					.car-model_info {
						flex: 1;
						padding-right: 10px;
						.car_title {
							height: auto;
							max-height: 20px;
							overflow: hidden;
							.brand {
								font-family: "Roboto";
								font-style: normal;
								font-weight: 700;
								font-size: 17px;
								line-height: 19px;
								letter-spacing: 0.15px;
								color: var(--inverted-bg-color);
							}
						}

						p {
							font-family: "Roboto";
							font-style: normal;
							font-weight: 400;
							font-size: 12px;
							line-height: 16px;
							color: var(--inverted-bg-color);
						}
						.car_class {
							font-size: 17px;
							color: var(--text-color);
						}
					}
				}

				.govnumber_car {
					border: 1px solid var(--inverted-bg-color);
					border-radius: 6px;
					padding: 0 7px;
					margin-right: 21px;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 24px;
					color: var(--inverted-bg-color);
				}
			}
		}

		.navigation-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 10px;

			.icon-nav {
				@include icon-outline;
			}

			.add_car_icon {
				path {
					fill: var(--inverted-bg-color);
					stroke: #212121;
				}
			}
		}

		.info-item {
			margin-top: 10px;
			h1 {
				color: var(--text-color-1);
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
				letter-spacing: 0.1px;
			}

			.nav-line {
				display: flex;
				flex-direction: row;
				margin: 20px 0px 10px 0px;
				justify-content: space-around;
				.nav-btn {
					width: 50%;
					border-radius: 0px 100px 100px 0px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					color: var(--order-detail-address-text);
					background-color: var(--main-bg-color-secondary);
					.check_icon {
						margin-right: 11px;
						@include icon-outline;
					}

					.check_icon-active {
						margin-right: 11px;
						width: 18px;
						height: 18px;
						path {
							stroke: var(--main-bg-color-secondary);
							stroke-width: 3px;
						}
					}

					span {
						font-family: "Roboto";
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 20px;
						letter-spacing: 0.1px;
					}
					&-left {
						@extend .nav-btn;
						border-radius: 100px 0px 0px 100px;
						margin-right: 0px;

						span {
							font-family: "Roboto";
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 20px;
							letter-spacing: 0.1px;
						}
					}
				}
				.active {
					color: var(--main-bg-color);
					background-color: var(--inverted-bg-color);
				}
			}
		}

		.add_auto {
			display: flex;
			align-items: center;
			margin-top: 40px;
			padding-bottom: 40px;
			.icon_plus {
				@include icon-outline;
				padding-right: 10px;
				path {
					stroke-width: 2.3px;
				}
			}
			.link_add_cars {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 0.5px;
				color: var(--text-color-1);
			}
		}
	}
}

[data-theme="light"] {
  --main-bg-color: #ffffff;
  --inverted-bg-color: #212121;
  --main-bg-color-secondary: #eeeeee;
  --main-bg-inverted-color: #e0e0e0;

  --warning-bg: #fdebea;

  --modal-main-text: #1c1b1f;

  --border-color-1: #eeeeee;

  --informing-bg-1: #ffde17;
  --informing-bg-2: #1db960;
  --informing-bg-3: #ee392e;
  --informing-bg-4: #0782f3;

  --icon-color-1: #212121;

  --text-color: #616161;
  --text-color-1: #212121;
  --text-color-2: #616161;
  --text-color-3: #616161;
  --text-color-4: #212121;
  --text-color-5: #212121;
  --text-disable-color: #9e9e9e;

  --placeholder-color-1: #9e9e9e;

  --button-bg: #212121;
  --button-text-color-1: #ffffff;
  --button-text-color-disabled: #ffffff;
  --button-color-disabled: #616161;

  --border-color-1: #eeeeee;

  --button-start-work-bg: #212121;
  --button-stop-work-bg: #ffde17;
  --button-start-work-text-color: #ffffff;
  --button-stop-work-text-color: #212121;
  --button-gps: #0782f3;

  --order-label-sum: #ffde17;
  --order-label-distance: #212121;
  --order-label-tariff: #1db960;
  --order-label-time: #eeeeee;
  --order-text-sum: #212121;
  --order-text-distance: #ffffff;
  --order-text-tariff: #ffffff;
  --order-text-time: #212121;

  --order-main-bg-color: #eeeeee;
  --order-detail-address-bg: #ffffff;
  --order-detail-address-text: #212121;

  --bg-inspectation-car: #fff4cc; //!TODO нужно подобрать цвет

  --error-bg-input: #fff0ef;
  --default-bg-input: #eeeeee;

  --pin-1: #414042;

  --search-input-1: #eeeeee;

  --marker-bottom-color: rgba(7, 130, 243, 0.1);

  --secondary-border-color: #494949;

	// skeleton
	--skeleton-color: #fff;
	--skeleton-shadow: rgba(0 0 0 / 20%);
}

@import "src/styles/mixins/mixin.scss";

// .cardocuments-section {
//     height: 100vh;
//     margin: 0 auto;
//     display: flex;
//     flex-direction: column;
//     background-color: rgba(244, 244, 244, 0.8);
//     @include main-font;
//     .top-nav {
//         @include main-bg;
//         @include top-nav-paddings;
//         display: flex;
//         align-items: center;
//         color: #fff;
//         a {
//             display: flex;
//             .ArrowLeft {
//                 @include arrow-margin;
//                 color: #fff;;
//                 @include arrow-size;
//             }
//         }
//         .top-nav-text {
//             margin: 0px;
//             padding-left: 35px;
//             @include nav-font-size
//         }
//     }

//     .last-inspection-block {
//         margin: 8px 10px 0px 10px;
//         display: flex;
//         flex-direction: column;
//         @include text-font-size;
//         background-color: #fff;
//         box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
//         p {
//             margin: 5px 0px 5px 0px;
//         }
//         .row-block {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-around;
//             margin: 10px 0px 10px 0px;
//             .col-block {
//                 display: flex;
//                 flex-direction: column;
//             }
//             .status {
//                 align-self: center;
//             }
//             .red {
//                 color: red;
//             }
//             .green {
//                 color: green;
//             }
//             .blue {
//                 color: blue;
//             }
//         }
//         .FaAngleDoubleDown {
//             @include arrow-size;
//             background-color: #fff;
//             border-radius: 50%;
//             border: 2px solid #000;
//             align-self: center;
//             margin: 0px 0px 10px 0px;
//         }
//         .imgBlock {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//         }
//     }

//     .documents-list {
//         list-style-type: none;
//         margin: 8px 10px 0px 10px;
//         padding: 0px;
//         .document-item {
//             display: flex;
//             flex-direction: column;
//             margin-top: 10px;
//             background-color: #fff;
//             box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
//             .load-img-form {
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     width: 100%;
//                     height: 40px;
//                     background-color: rgba(218, 215, 215, 1)
//                 }
//             .download {
//                 background-color: #000;
//                 color: #fff;
//                 padding: 5px 25px 5px 25px;
//                 border: none;
//                 width: 100%;
//                 margin: 15px 0px 15px 0px;
//             }
//             .line-items {
//                 display: flex;
//                 flex-direction: row;
//                 justify-content: space-between;
//                 padding: 5px 15px 5px 15px;
//                 .item-text {
//                     @include text-font-size;
//                     padding: 0px;
//                     margin: 0px 0px 0px 0px;
//                 }
//                 .col-block {
//                     display: flex;
//                     flex-direction: column;
//                     width: 100%;
//                     input {
//                         margin-top: 10px;
//                     }
//                     .error {
//                         color: red;
//                         @include error-font-size;
//                         margin: 0px;
//                     }
//                     .success {
//                         color: green;
//                         @include error-font-size;
//                         margin: 0px;
//                     }
//                     .requestTOBlock {
//                         .list-text {
//                             @include text-font-size;
//                             @include text-col;
//                             margin: 5px 0px 5px 0px;
//                         }
//                         .docs-list {
//                             .list-item-text {
//                                 @include text-font-size;
//                             }
//                         }
//                     }
//                 }
//                 .FaAngle {
//                     @include icon-size;
//                     align-self: center;
//                 }
//             }
//             .sub-download {
//                 border-top: 1px solid grey;
//                 padding: 10px 5px 10px 5px;
//                 display: flex;
//                 justify-content: center;
//             }
//         }
//         .save-btn {
//             width: 100%;
//             margin: 10px 0px 10px 0px;
//             padding: 14px 25px;
//             @include text-font-size;
//             background-color: #000;
//             color: #fff;
//             border: none;
//         }
//         .center {
//             align-items: center;
//         }
//     }
//     .license-img {
//         width: 90%;
//         height: auto;
//         margin: 10px 0px 10px 0px;
//         align-self: center;
//     }
// }

.car-documents_section {
  background-color: var(--main-bg-color);

  .container {
    @include container;
    .upload-car_photo {
      margin-top: 10px;
      .document-auto_block {
        padding: 15px 0;
      }
      .back_icon {
        padding-bottom: 10px;
        .arrowleft_icon {
          @include icon-outline;
        }
      }

      .block-photo_phase {
        display: flex;
        justify-content: space-between;

        .info-title_pahse {
          h1 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 8px;
            color: var(--inverted-bg-color);
          }

          h2 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--text-color-5);
          }

          .link_guide {
            color: inherit;
            text-decoration: underline;
          }
        }
      }

      .document-auto_block {
        .border-block_passport {
          border-bottom: 1px solid var(--border-color-1);
          padding-bottom: 15px;
        }
        h2 {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          color: var(--inverted-bg-color);
        }
        .add_document-btn {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-top: 16px;
          padding: 0;
          width: 100%;
          padding: 16px 10px 16px 10px;
          background-color: var(--main-bg-color-secondary);

          // padding: 26px 33px 26px 72px;

          .name-block_type {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--inverted-bg-color);
            text-align: left;
          }
          .named_img {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--text-color);
            text-align: left;
            // padding-bottom: 16px;
          }

          .add_icon-block {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            background: #ffffff;
            min-width: 40px;
            border-radius: 100px;
            min-height: 40px;

            .add_icon {
              path {
                stroke-width: 2.3px;
              }
            }
          }
        }
      }

      .next-block_btn {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        .next-btn {
          @include btn-continue;
          width: 94%;
        }
      }
    }
  }
}

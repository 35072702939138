@import "src/styles/mixins/mixin.scss";

.contactus-section {
  min-height: 100dvh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include main-font;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  a {
    display: flex;
    .ArrowLeft {
      @include icon-outline;
      padding-left: 10px;
      padding-bottom: 10px;
    }
  }

  .top-nav {
    padding-top: 10px;
  }

  .top-nav-text {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    padding: 0px 16px;
    color: var(--inverted-bg-color);
  }

  .contact-text {
    margin: 16px;
    font-weight: 700;
    font-size: 16px;
    color: var(--inverted-bg-color);
  }

  .contact-mess_block {
    margin: 16px;
    .title-info_support {
      padding-bottom: 18px;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: var(--inverted-bg-color);
    }
    .block-mess-support {
      display: flex;
      align-items: center;
      padding-left: 3px;
      .TelegramIcon {
        font-size: 22px;
        color: #31a7dd;
      }
      .link-mess {
        padding-left: 14px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: inherit;
        text-decoration: underline;
      }
    }
    .text {
      padding-top: 15px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--inverted-bg-color);
    }
  }

  .contact-phone_block {
    margin: 16px;
    .title-info_support {
      padding-bottom: 15px;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      letter-spacing: 0.1px;
      color: var(--inverted-bg-color);
    }
    .block-phone_support {
      position: relative;
      .block-operator {
        display: flex;
        align-items: center;
        a {
          padding: 9px 20px 9px 35px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
          color: inherit;
          text-decoration: underline;
        }
        .VodafoneIcon {
          width: 22px;
          height: 21px;
          position: absolute;
        }
        .KyivstarIcon {
          width: 21px;
          height: 20px;
          position: absolute;
          left: 0px;
        }
        .LifecellIcon {
          width: 19px;
          height: 19px;
          position: absolute;
          left: 1px;
        }
      }
    }
    .text {
      padding-top: 15px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--inverted-bg-color);
    }
  }

  .contact-zendesk_block {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 40px;
    .continue_btn {
      @include btn-continue;
      width: 90%;
    }
  }
}

.btn-block {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  .chat_btn {
    width: 94%;
    @include btn-continue;
  }
}

@import "src/styles/mixins/mixin.scss";

.editpassword-section {
  background-color: var(--main-bg-color);

  .container {
    @include container;
    min-height: 100vh;

    .error_field {
      @include error-input-field;
    }

    .navigation-block {
      margin-bottom: 25px;
      margin-top: 10px;

      .icon-nav {
        @include icon-outline;
      }

      h1 {
        margin-top: 10px;
        color: var(--text-color-1);
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.1px;
      }
    }

    .editpassword-input_block {
      display: flex;
      flex-direction: column;
      margin-top: 9px;
      margin-bottom: 20px;

      #error {
        color: red;
      }
      p {
        margin: 0;
        padding: 0 11px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: var(--text-color);
      }
      .input_field {
        color: var(--text-color-1);
        width: 100%;
        padding: 18px 0 17px 0;
      }
      &::placeholder {
        color: var(--placeholder-color-1);
      }
    }

    .update-pass_btn {
      width: 100%;
      margin-top: auto;
      margin-bottom: 60px;
      .udatepass-btn {
        @include btn-width;
        background-color: var(--button-bg);
        color: #ffffff;
        border-radius: 12px;
        border: none;
      }
      .btn_disabled {
        color: var(--button-text-color-disabled);
        background-color: var(--text-disable-color);
      }
    }
  }
}

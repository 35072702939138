@import "src/styles/mixins/mixin.scss";

.article-section {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  @include main-font;
  padding: 0 16px;
  .top-nav {
    // @include main-bg;
    // @include top-nav-paddings;
    // color: #fff;
    margin-top: 10px;
    h1 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.1px;
      padding-bottom: 8px;
      color: var(--inverted-bg-color);
      margin-top: 17px;
    }
    a {
      display: flex;
      .ArrowLeft {
        @include icon-outline;
        margin-left: -6px;
        padding-bottom: 10px;
      }
    }
    .top-nav-text {
      margin: 0px;
      padding-left: 35px;
      @include nav-font-size;
    }
  }
  .article-block {
    display: flex;
    flex-direction: column;
    p {
      margin: 0px;
    }
    .theme {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      display: flex;
      line-height: 30px;
      /* identical to box height, or 125% */
      letter-spacing: 0.1px;
      padding: 0px 16px 10px 0px;
    }
    .text {
      font-family: "Roboto";
      font-style: normal;
      white-space: pre-line;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.5px;
      font-weight: 300;
      padding-bottom: 5px;
      color: var(--inverted-bg-color);
    }
    .date {
      padding-bottom: 16px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: var(--text-color);
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.cardList {
  padding-top: 18px;
  .navCard {
    padding: 15px 0;
    @include border;
  }

  .cardsSection {
    display: flex;
    justify-content: space-around;
    .cardsItems {
      display: flex;
      align-items: center;
      flex: 1;
      padding-left: 19px;

      .creditCard {
        path {
          fill: var(--inverted-bg-color);
        }
        circle {
          stroke-width: 1px;
          stroke: var(--inverted-bg-color);
        }
      }

      .titleCard {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
        color: var(--text-color);
      }

      .creditCard {
        padding-right: 10px;
      }

      .selectedCard {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #1db960;
        padding: 4px 8px;
        background: rgba(29, 185, 96, 0.2);
        border-radius: 100px;
        margin-left: 10px;
      }
    }
    .checkIcon {
      padding-right: 20px;
      @include icon-outline;
    }
  }
}

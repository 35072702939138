.modal-g {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.401);
  top: 0;
  left: 0;
  justify-content: end !important;

  .modal-c {
    padding: 0;
    align-items: flex-start;

    .modal {
      padding: 0;
      align-items: flex-end;

      .modalalert-section {
        padding: 0;
        align-items: flex-start;
        margin-bottom: 40px;

        .modal-text-top {
          text-align: center !important;
          align-items: center !important;
          width: 100%;
          margin-top: 20px;

          .top-nav-text-sort {
            font-family: "Roboto";
            display: block;
            text-align: center !important;
            align-items: center !important;
            margin-left: auto;
            margin-right: auto;
            color: var(--modal-main-text);
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            letter-spacing: 0.1px;
          }
        }

        .sorts-container {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          margin-left: 20px;
          margin-right: 20px;
          width: calc(100% - 40px);
          padding-bottom: 50px;

          .border-modal-sort {
            margin-top: 16px;
            content: "";
            width: 100%;
            margin-bottom: 14px;
            border-bottom: 1.5px solid var(--border-color-1);
          }
          .sort-category {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .sort-text-filter {
              font-family: "Roboto";
              font-style: normal;
              color: var(--modal-main-text);
              font-weight: 400;
              font-size: 16px;
              padding: 0px !important;
              /* identical to box height, or 125% */
              background-color: transparent;
              border: none;
            }
          }
        }
      }
    }
  }
}

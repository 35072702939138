@import "src/styles/mixins/mixin.scss";

.registration-section {
	min-height: 100vh;
	background-color: var(--main-bg-color);

	.hidden_block {
		display: none;
	}

	#error {
		@include error-text;
	}

	.container {
		@include container;
		.registration-section__main {
			.register_icon {
				display: flex;
				align-items: center;
				margin-top: 10px;

				.close-icon {
					@include icon-outline;
				}
			}

			input {
				font-size: 16px;
			}

			.register-section__login {
				margin-top: 7px;
				.login_title {
					text-align: start;

					.login-title__register {
						margin: 0;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
						color: var(--inverted-bg-color);
					}

					.login-title__info {
						font-family: "Roboto";
						margin-top: 8px;
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: var(--text-color);
					}
				}
			}

			.register_phone-block {
				display: flex;
				align-items: center;
				margin-top: 25px;

				.register_phone {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 11px 13px 8px 14px;
					border-radius: 12px;
					background-color: var(--main-bg-color-secondary);
					width: 18%;
					.registericon_flag {
						padding-right: 6px;
					}

					.number_country {
						font-family: "Roboto";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 19px;
						color: var(--text-color-1);
						padding-left: 5px;
					}
				}

				.register_input-phone {
					margin-left: 4px;
					display: flex;
					align-items: center;
					width: 100%;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					background-color: var(--main-bg-color-secondary);
					border-radius: 10px;
					padding: 0;
					height: 50px;

					.input-code {
						padding: 0 5px 0 10px;
					}

					.input_block {
						width: 100%;
						color: var(--text-color-1);
					}

					.phone_input {
						text-indent: 0;
						width: 100%;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						padding: 0;
						border-radius: 0;
					}
				}
			}

			.register_info-block {
				display: flex;
				flex-direction: column;
				margin-top: 9px;
				p {
					margin: 0;
					padding: 0 14px;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 24px;
					color: var(--text-color);
				}
				.input_field {
					padding: 18px 0 17px 0;
					width: 100%;
				}

				.error_field {
					@include error-input-field;
				}
			}

			.register-btn_continue_block {
				margin-top: 72px;
				display: flex;
				justify-content: center;

				.continue_btn {
					width: 100%;
					background-color: var(--button-bg);
					color: #ffffff;
					padding: 16px 93px 16px 93px;
					border-radius: 12px;
					border: none;
				}

				.btn_disabled {
					color: var(--button-text-color-disabled);
					background-color: var(--text-disable-color);
				}
			}

			.footer-info {
				text-align: center;
				margin-top: 210px;
				padding-bottom: 136px;
				p {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					color: var(--text-color);
					padding: 0;
					margin: 0;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					padding-bottom: 6px;
				}

				.link_register {
					text-decoration: underline;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					color: var(--inverted-bg-color);
				}
			}

			.next-block_btn {
				display: flex;
				align-self: center;
				margin: 35px 15px 0 15px;
				.next-btn {
					@include btn-width;
					background-color: var(--button-bg);
					color: #ffffff;
					border-radius: 12px;
					border: none;
				}
				.btn_disabled {
					color: var(--button-text-color-disabled);
					background-color: var(--text-disable-color);
				}
			}

			.block-rules {
				padding: 0 20px;
				p {
					color: var(--text-color-4);
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					margin-top: 23px;
				}
				a {
					color: var(--text-color-4);
					text-decoration: underline;
				}
			}

			.language-select_block {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 44px;
				padding-bottom: 50px;
				.lang_btn {
					position: relative;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					border-radius: 8px;
					padding: 6px 16px 6px 40px;
					color: var(--inverted-bg-color);
					background-color: var(--main-bg-color-secondary);

					.language_icon {
						position: absolute;
						left: 14px;
						top: 8px;
						width: 16px;
						height: 16px;

						@include icon-outline;
					}
				}
			}
		}
	}
}

.modalError {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.okBtn {
  text-transform: uppercase;
}

.supportBtn {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.supportBtnTitle {
}

@import "src/styles/mixins/mixin.scss";

.mainnews-section {
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);

  a {
    display: flex;
    .ArrowLeft {
      @include icon-outline;
      padding-left: 10px;
      padding-bottom: 10px;
    }
  }

  .top-nav {
    margin-top: 10px;
  }

  .top-nav-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
    letter-spacing: 0.1px;
    padding: 16px 16px 16px 16px;
  }

  .nav-line {
    display: flex;
    flex-direction: row;
    margin: 20px 0px 10px 0px;
    justify-content: space-around;
    .nav-btn {
      width: 50%;
      border-radius: 0px 100px 100px 0px;
      margin-right: 8px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--order-detail-address-text);
      background-color: var(--order-detail-address-bg);
      &-left {
        @extend .nav-btn;
        border-radius: 100px 0px 0px 100px;
        margin-left: 8px;
        margin-right: 0px;
      }
    }
    .active {
      color: var(--main-bg-color);
      background-color: var(--inverted-bg-color);
    }
  }
  .news-list {
    list-style-type: none;
    margin: 0px;
    padding: 16px 16px 80px 16px;
    .news-list-block {
      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: var(--text-color);
      }

      .news-item {
        @include border;
        padding: 10px 0;

        &:last-child {
          padding-bottom: 25px;
          border: none;
        }

        .no_read {
          background-color: var(--main-bg-color-secondary);
        }

        .news-block {
          border-radius: 16px;
          position: relative;

          .no_read__point {
            position: absolute;
            width: 10px;
            height: 10px;
            background: #ee392e;
            border-radius: 100px;
            right: 10px;
            top: 10px;
          }

          .main-news {
            padding: 19px 0 6px 16px;
            text-align: left;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.15px;
            color: var(--inverted-bg-color);
          }
          .title-news {
            padding: 0 70px 16px 16px;
            text-align: left;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: var(--text-color-4);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

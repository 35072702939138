@import "src/styles/mixins/mixin.scss";

.gtransactions-section {
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include main-font;
  background-color: var(--main-bg-color);
  color: var(--inverted-bg-color);
  @include container;

  .top-nav {
    margin-top: 10px;
    padding-bottom: 12px;
  }

  .ArrowLeft {
    @include icon-outline;
    padding-bottom: 10px;
  }
  .top-nav-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    padding-bottom: 12px;
  }
  .no-transactions {
    align-self: center;
    margin-top: 40px;
  }

  .transactions-list {
    list-style-type: none;
    padding: 0px;

    .transaction-list-block {
      h3 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 12px;
        color: var(--text-color);
      }

      .light_yellow {
        background-color: #fff4cc;
        border-radius: 20px;
        padding: 16px 8px;
      }

      .light_green {
        background-color: #ddf5e7;
        border-radius: 20px;
        padding: 16px 8px;
      }

      .light_red {
        background-color: #fdebea;
        border-radius: 20px;
        padding: 16px 8px;
      }
      .transaction-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        @include border;

        &:last-child {
          margin-bottom: 40px;
          border: none;
        }

        &:first-child {
          background-color: red;
        }

        .info-block {
          display: flex;
          align-items: center;

          .ArrowInfo {
            min-width: 30px;
            min-height: 30px;

            path {
              stroke-width: 1.5px;
            }
          }

          .info-title {
            padding: 0 12px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.5px;
            color: #2c2d31;
          }

          .status_info {
            padding-left: 15px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: var(--text-color);
          }
        }

        .current-amount {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
          padding-right: 11px;
          color: #2c2d31;
        }
      }
    }
  }
}

.rejected-operation {
  color: #ee392e !important;
  path {
    stroke: #ee392e;
  }
}

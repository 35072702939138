@import "src/styles/mixins/mixin.scss";

// .addcar-section {
//     height: 100vh;
//     margin: 0 auto;
//     display: flex;
//     flex-direction: column;
//  //   justify-content: space-between;
//     @include main-font;
//     .top-nav {
//         @include main-bg;
//         @include top-nav-paddings;
//         display: flex;
//         align-items: center;
//         color: #fff;
//         a {
//             display: flex;
//             .ArrowLeft {
//                 @include arrow-margin;
//                 color: #fff;;
//                 @include arrow-size;
//             }
//         }
//         .top-nav-text {
//             margin: 0px;
//             padding-left: 35px;
//             @include nav-font-size
//         }
//     }
//     .car-form {
//         display: flex;
//         flex-direction: column;
//         padding: 0px 10px 0px 8px;
//         height: 100%;
//     //    justify-content: space-evenly;
//             .brand {
//                 display: flex;
//                 flex-direction: row;
//                 justify-content: space-between;
//                 align-items: center;
//                 margin-top: 25px;
//             }
//             .error-line {
//                 .error {
//                     color: red;
//                     @include error-font-size;
//                     margin: 0px;
//                     position: absolute;
//                     margin-top: 5px;
//                 }
//             }
//             select {
//                 width: 50%;
//                 @include main-font;
//                 @include text-font-size;
//             }
//             input {
//                 border: none;
//                 @include border-bot-col;
//                 width: 100%;
//                 @include text-font-size;
//                 margin: 25px 0px 15px 0px;
//             }
//             .year {
//                 .error {
//                     margin-top: 10px;
//                 }
//             }
//             .number {
//                 .error {
//                     margin-top: 10px;
//                 }
//             }
//             .input-text {
//                 font-size: 4vw;
//                 @include text-col;
//                 text-align: center;
//             }
//             .prefs-block {
//                 @include text-font-size;
//                 margin-top: 10px;
//                 .prefs-list {
//                     display: flex;
//                     flex-direction: column;
//                     list-style-type: none;
//                     padding: 0px;
//                     .prefs-list-item {
//                         align-items: center;
//                         input {
//                             width: auto;
//                             margin: 10px 10px 20px 30px;
//                         }
//                     }
//                 }
//             }
//             .add-btn {
//                 background-color: #000;
//                 color: #fff;
//                 @include text-font-size;
//                 padding: 10px 0 10px 0;
//                 border: none;
//             }
//     }

// }

.addcar-section {
  background-color: var(--main-bg-color);
  .container {
    @include container;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    .hidden_block {
      display: none;
    }
    .addcar-selection {
      background-color: var(--main-bg-color);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .addcar-section__main {
      margin-top: 10px;
      .error {
        @include error-text;
        position: absolute;
        bottom: -17px;
        padding-left: 17px;
      }

      .error_height {
        bottom: 2px;
      }
      .block_title {
        margin-bottom: 7px;
        color: var(--inverted-bg-color);
        font-size: 18px;
        .back_icon {
          .arrowleft_icon {
            @include icon-outline;
          }
        }
      }

      .block-photo_phase {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 14px;
        // margin-top: 20px;
        .info-title_pahse {
          p {
            color: var(--inverted-bg-color);
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
          }
        }
      }

      .set_auto_block {
        @include border;
        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          padding: 0 18px;
          color: var(--text-color);
        }
        .btn-select {
          background-color: var(--main-bg-color-secondary);
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 7px 18px 7px 18px;
          margin-bottom: 20px;
          .select_item {
            color: var(--placeholder-color-1);
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
          }
          .select_item--active {
            color: var(--inverted-bg-color);
          }
          .icon-block_down {
            .down-icon {
              @include icon-outline;
              padding-top: 10px;
              color: var(--icon-color-1);
            }
          }
          .move_right {
            margin-left: 13px;
          }
        }

        .car_type {
          display: flex;
          justify-content: center;
          // justify-content: space-between;
        }

        .block-number_auto {
          margin-bottom: 20px;
          .number_auto {
            width: 100%;
            padding: 16px 0;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--inverted-bg-color);
            &::placeholder {
              color: var(--placeholder-color-1);
            }
          }
        }

        .class-auto_block {
          padding-bottom: 15px;
        }
      }

      .prefs-block {
        padding-top: 27px;
        p {
          // padding-bottom: 24px;
          color: var(--inverted-bg-color);
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
        }

        .prefs-list {
          padding: 0;
          margin-top: 10px;
          li {
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 20px 12px 0;
            border-bottom: 1px solid var(--border-color-1);
            list-style: none;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--inverted-bg-color);

            .check_icon {
              @include icon-outline;
            }
          }
        }
      }

      .confirm_block {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        padding-bottom: 30px;
        .confirm_btn {
          width: 94%;
          @include btn-continue;
        }

        .btn_disabled {
          color: var(--button-text-color-disabled);
          background-color: var(--text-disable-color);
        }
      }
    }
  }
}

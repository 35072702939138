@import "src/styles/mixins/mixin.scss";
@import "src/styles/colors/colors.scss";

.maplibre-map {
  width: 100%;
  height: 100%;

  .maplibregl-ctrl-top-left {
    z-index: 999;
    top: 150px;
  }
}

.location-button,
.map-navigation,
.view-3d-button,
.speed {
  position: absolute;
  z-index: 100;
  background-color: var(--main-bg-color);
  border-radius: 16px;
  border: none;
  outline: none;
}

.location-button {
  width: 44px;
  height: 44px;
  top: 107px;
  right: 16px;

  @media screen and (max-height: 730px) {
    top: 30px;
  }
}

.location-button-tracking {
  .location-image {
    fill: var(--button-gps);
    stroke: var(--button-gps);

    path {
      fill: var(--button-gps);
      stroke: var(--button-gps);
    }
  }
}

.location-image {
  fill: var(--inverted-bg-color);
  stroke: var(--inverted-bg-color);

  path {
    fill: var(--inverted-bg-color);
    stroke: var(--inverted-bg-color);
  }
}

.map-navigation {
  display: flex;
  background-color: $green-200;
  bottom: 25px;
  right: 72px;
  padding: 5px;
  width: 34px;
  height: 34px;
  border-radius: 50%;

  path {
    fill: $white;
  }

  &.active {
    path {
      fill: $yellow-500;
    }
  }
}

.view-3d-button {
  width: 44px;
  height: 44px;
  top: 100px;
  left: 16px;
  font-weight: 700;
  font-size: 20px;
  color: var(--inverted-bg-color);

  @media screen and (max-height: 730px) {
    top: 30px;
  }
}

.speed {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 16px;
  width: 60px;
  height: 54px;
  bottom: 15px;
  left: 16px;
  justify-content: center;
  align-items: center;
  color: var(--inverted-bg-color);
}

.car-marker,
.pickup-point-marker {
  background-repeat: no-repeat;
  svg {
    display: none;
  }
}

.car-marker {
  background-image: url("../../images/car-marker.svg");
  background-size: contain;
  width: 50px;
  height: 50px;
  z-index: 15;
}

.pickup-point-marker {
  background-image: url("../../images/map-pin-green.svg");
  background-size: contain;

  width: 33px;
  height: 40px;
}

.blackPoint,
.grayPoint {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.pointBig {
  width: 32px;
  height: 32px;
}

.innerBlackPoint {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerPointBig {
  width: 18px;
  height: 18px;
}

.blackPoint {
  background-color: var(--inverted-bg-color);

  .innerBlackPoint {
    background-color: var(--main-bg-color);
    color: var(--inverted-bg-color);
  }
}

.grayPoint {
  background-color: var(--main-bg-color-secondary);

  .innerBlackPoint {
    background-color: var(--inverted-bg-color);
    color: var(--main-bg-color-secondary);
  }
}

// animation for radius

.pulse {
  animation: pulse-animation 2s infinite; /* Применение анимации к слою */
}

@keyframes pulse-animation {
  0% {
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 0.7;
  }
  100% {
    fill-opacity: 0;
  }
}

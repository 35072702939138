@import "src/styles/mixins/mixin.scss";

.forgot-section {
  background-color: var(--main-bg-color);

  .container {
    @include container;
    min-height: 100vh;

    .error_field {
      @include error-input-field;
    }

    .navigation-block {
      margin-bottom: 25px;
      margin-top: 10px;
      .icon-nav {
        @include icon-outline;
      }

      h1 {
        margin-top: 10px;
        color: var(--text-color-1);
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.1px;
      }
    }

    .forgot-form {
      .forgot-text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: var(--text-color);
      }
      .forgot-block {
        display: flex;
        flex-direction: column;
        margin-top: 90px;

        #error {
          color: #ee392e;
          padding: 5px 7px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
        }
        p {
          margin: 0;
          padding: 5px 11px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: var(--text-color);
        }
        .input_field {
          color: var(--text-color-1);
          width: 100%;
          padding: 18px 0 17px 0;
          font-size: 16px;
        }
      }
    }

    .reg-button {
      position: fixed;
      display: flex;
      justify-content: center;
      padding-bottom: 25px;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 84%;

      @media screen and (max-height: 465px) {
        width: 100%;
        position: static;
        display: flex;
        justify-content: center;
        margin-top: 80px;
        padding-bottom: 20px;
        transform: none;
      }

      .reg-btn {
        @include btn-width;
        background-color: var(--button-bg);
        color: #ffffff;
        border-radius: 12px;
        border: none;
      }
      .btn_disabled {
        color: var(--button-text-color-disabled);
        background-color: var(--text-disable-color);
      }
    }
  }
}

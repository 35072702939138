@import "src/styles/mixins/mixin.scss";
@import "src/styles/colors/colors.scss";

.reviewOrderWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: --var(--inverted-bg-color);
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  img {
    margin-top: 25px;
    display: flex;
    border-radius: 50%;
    width: 100%;
    max-width: 56px;
  }

  textarea {
    margin-top: 25px;
    border-radius: 20px;
    display: flex;
    width: 100%;
    border: 1px solid var(--main-bg-color-secondary);
    resize: none;
    outline: none;
    padding: 15px 20px;
    font-size: 16px;
    background-color: var(--main-bg-color);
    color: var(--inverted-bg-color);
  }

  button {
    margin-top: 25px;
  }
}

.closeIcon {
  margin: 0 0 0 auto;

  path {
    stroke: var(--inverted-bg-color);
  }
}

.reviewOrderTime {
  margin-top: 10px;
  font-weight: 700;
  opacity: 0.7;
}

.customerName {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 700;
}

.ratingWrap {
  margin-top: 15px;
  display: flex;
  column-gap: 10px;
}

.ratingItem {
  display: flex;
}

.star {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;

  path {
    stroke: $yellow-500;
    width: 100%;
  }

  &Yellow {
    path {
      fill: $yellow-500;
    }
  }
}

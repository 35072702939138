.salaryModuleDateWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: var(--main-bg-color-secondary);
  align-items: center;
  border-radius: 20px;
  position: relative;
}

.salaryModuleDateItems {
  display: grid;
  color: var(--inverted-bg-color);
  grid-template-columns: 1fr 1fr 1fr;

  li {
    padding: 4px 10px;
    border-radius: 20px;
    height: 100%;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    transition: all 0.3s ease;
  }
}

.activeDate {
  background-color: var(--order-label-sum);
  color: var(--order-text-sum);
}

.calendarIcon {
  display: flex;
  padding: 0px 10px;
  width: 100%;
  height: 28px;
  max-width: 45px;
  border-radius: 20px;

  path {
    fill: var(--inverted-bg-color);
  }
}

.salaryDatePickerWrap {
  position: absolute;
  top: 30px;
  right: 0;
  width: 100%;
  z-index: 100;

  .datePickerStyles {
    width: 100%;
  }
}

@import "src/styles/mixins/mixin.scss";

.finishreg-section {
  background-color: var(--main-bg-color);
  min-height: 100vh;

  .container {
    @include container;
    .block_title {
      padding-top: 10px;
      .back_icon {
        padding-bottom: 30px;
        color: var(--inverted-bg-color);
        font-size: 18px;
      }
    }

    .main-info {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .info_finish {
        h1 {
          padding-top: 24px;
          color: var(--inverted-bg-color);
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
        }
        h3 {
          padding-top: 8px;
          color: var(--text-color);
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          max-width: 270px;
          padding-left: 6px;
        }
      }
    }

    .block-btn_app {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 220px 5px 105px 5px;
      .btn-app {
        @include btn-continue;
        @include btn-width;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }
    }

    .block_help {
      display: flex;
      justify-content: center;
      // padding-bottom: 20px;
      .phone_icon {
        @include icon-modal;
      }
      p {
        text-decoration: underline;
        color: var(--inverted-bg-color);
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
      }
    }
  }
}

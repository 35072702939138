@import "src/styles/mixins/mixin.scss";
@import "/src/shared/ui/CircularProgressBar/progressbar.module.scss";

.loaderWrap {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--main-bg-color);
  justify-content: center;
  flex-direction: column;

	.standart-loader {
		position: initial;
		min-height: 0%;
		transform: translateX(0%);
	}
}

.showMenuSpace {
	height: calc(100% - 80px);
}

.loaderText {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 40px;
	text-align: center;
	flex-direction: column;
	color: var(--inverted-bg-color);
	row-gap: 5px;
}

.logoCarma {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;

	path {
		fill: var(--inverted-bg-color);
	}
}

@mixin resets-block-styles {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@mixin reset-block-and-inner {
  @include resets-block-styles;
  * {
    @include resets-block-styles;
  }
}


@mixin phone-line {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@mixin phone-tpl {
  margin: 0px 0px 0px 0px;
  position: absolute;
  padding-bottom: 7px;
}
@mixin phone {
  width: 100%;
  margin-bottom: 5px;
  border: none;
  border-bottom: 2px solid #fea523;
}
@mixin pad {
  padding: 0px 0px 0px 20px;
}

@mixin main-font {
  font-family: "Roboto";
  font-style: normal;
}

// MY TEST
@mixin icon-outline {
  path {
    stroke: var(--inverted-bg-color);
  }
}

@mixin icon-fill {
  path {
    fill: var(--main-bg-color);
    // stroke: var(--inverted-bg-color);
  }
}

@mixin indent-top {
  margin-top: 10px;
}

@mixin main-bg {
  background-color: #fea523;
}

@mixin text-col {
  color: #fea523;
}

@mixin border-bot-col {
  border-bottom: 2px solid #fea523;
}

@mixin arrow-margin {
  margin-left: 20px;
}

@mixin pad-left {
  padding-left: 35px;
}

@mixin pad-right {
  padding-right: 20px;
}

@mixin right-nav-margin {
  margin-right: 20px;
}

@mixin top-nav-paddings {
  padding: 11px 0 11px 0;
}

@mixin nav-font-size {
  font-size: 5vw;
}

@mixin text-font-size {
  font-size: 4vw;
}

@mixin error-text {
  color: #ee392e;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
}

@mixin error-input-field {
  background-color: var(--error-bg-input);
}

@mixin error-font-size {
  font-size: 3vw;
}

@mixin user-img-size {
  //    width: 15vw;
  //    height: 15vw;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}

@mixin sub-icon-size {
  height: 5vw;
  width: 5vw;
}

@mixin icon-size {
  height: 6vw;
  width: 6vw;
}

@mixin menu-icon-size {
  height: 7vw;
  width: 7vw;
}

@mixin arrow-size {
  height: 5vw;
  width: 5vw;
}

@mixin container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 16px;
}

@mixin medal-size {
  height: 4vw;
  width: 4vw;
}

@mixin btn-width {
  width: 100%;
}

@mixin btn-continue {
  background-color: var(--button-bg);
  color: #ffffff;
}

@mixin input_text {
  color: var(--placeholder-color-1);
}

@mixin border {
  border-bottom: 1px solid var(--border-color-1);
}

@mixin btn-disabled {
  color: var(--button-text-color-disabled);
  background-color: var(--text-disable-color);
}

@mixin icon-modal {
  color: var(--inverted-bg-color);
  font-size: 20px;
  padding-right: 10px;
}

@mixin modal-active {
  background-color: var(--main-bg-color);
}

@mixin modal {
  background: rgba(33, 33, 33, 0.8);
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: fixed;
  top: 0;
  z-index: 2005;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
@mixin modal-border {
  border: 2px solid #000;
  border-radius: 5px;
}

@mixin btn-bb {
  border: 2px solid #000;
  border-radius: 5px;
}
@import "src/styles/mixins/mixin.scss";

// @media screen and (max-height: 750px) {
//   .upload-section {
//     .container {
//       .confirm_block {
//         position: static;
//         display: flex;
//         justify-content: center;
//         margin-top: 40px;
//         padding-bottom: 20px;
//         transform: none;
//       }
//        .confirm_btn {
//         width: 300px;
//         // position: relative !important;
//       }
//     }
//   }
// }

.upload-section {
	min-height: 100vh;
	background-color: var(--main-bg-color);

	.container {
		@include container;
		min-height: 100vh;

		.upload-user_photo {
			.back_icon {
				margin-top: 10px;
				margin-bottom: 10px;
				@include icon-outline;
			}

			h1 {
				color: var(--inverted-bg-color);
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
			}

			.block_image {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				background-color: var(--main-bg-color-secondary);
				border-radius: 28px;
				margin-top: 32px;

				h2 {
					color: var(--inverted-bg-color);
					padding-top: 24px;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 24px;
				}

				p {
					max-width: 200px;
					color: var(--text-color);
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					text-align: center;
				}

				.upload-profile_img {
					display: flex;
					margin-top: 24px;
					//@include user-img-size;
					width: 120px;
					height: 120px;
					border: 1px solid inherit;
					border-radius: 50%;
				}

				.block-newphoto {
					padding-top: 24px;
					padding-bottom: 46px;

					.new_photo {
						padding: 6px 16px 6px 0;
						display: flex;
						align-items: center;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: #fff;
						background-color: var(--button-bg);

						.camera_icon {
							width: 18px;
							height: 18px;
							padding: 0 9px 0 17px;
							@include icon-fill;
						}
					}
				}
			}
		}

		.upload-license_photo {
			.back_icon {
				margin-top: 10px;
				margin-bottom: 10px;
				@include icon-outline;
			}

			h1 {
				color: var(--inverted-bg-color);
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
				letter-spacing: 0.1px;
			}

			.block_image {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				background-color: var(--main-bg-color-secondary);
				border-radius: 28px;
				margin-top: 32px;

				h2 {
					color: var(--inverted-bg-color);
					padding-top: 40px;
					padding-bottom: 2px;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 24px;
					letter-spacing: 0.5px;
					width: 250px;
					text-align: center;
				}

				p {
					max-width: 200px;
					color: var(--text-color);
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.25px;
					text-align: center;
				}

				.upload-profile_img {
					padding-top: 24px;
					//@include user-img-size;
					width: 271px;
					height: 168px;
					// border: 1px solid inherit;
					// border-radius: 50%;
				}

				.block-newphoto {
					padding: 40px 0;

					.new_photo {
						padding: 6px 16px 6px 0;
						display: flex;
						align-items: center;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: #fff;
						background-color: var(--button-bg);

						.camera_icon {
							width: 16px;
							height: 16px;
							padding: 0 9px 0 17px;
							@include icon-fill;
						}
					}
				}
			}
		}

		.upload-car_photo {
			padding-top: 40px;

			.back_icon {
				margin-bottom: 10px;
				.arrowleft_icon {
					@include icon-outline;
				}
			}
			h1 {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 30px;
				padding-bottom: 8px;
				color: var(--inverted-bg-color);
			}

			h2 {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				padding-bottom: 24px;
				color: var(--text-color-5);
			}

			.document-item_block {
				.document-item {
					.add_document-btn {
						display: flex;
						.add_icon-block {
							.add_icon {
								font-size: 22px;
								color: var(--inverted-bg-color);
							}
						}
					}
				}
			}
		}

		.uploadinfo-section__main {
			display: flex;
			flex-direction: column;
			min-height: 100vh;
			.block_title {
				display: flex;
				margin-top: 10px;
				.back_icon {
					.arrowleft_icon {
						@include icon-outline;
					}
				}

				.title_text {
					margin-top: 3px;
					color: var(--inverted-bg-color);
					padding-left: 15px;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 19px;
				}
			}

			.block-photo_phase {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 28px;

				.info-title_pahse {
					p {
						color: var(--inverted-bg-color);
						font-family: "Roboto";
						font-style: normal;
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
					}
				}
			}

			.block-profile_photo {
				position: relative;
				.step {
					color: var(--placeholder-color-1);
					padding-bottom: 18px;
					text-align: center;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 20px;
				}

				.block-user_photo {
					border-bottom: 1px solid var(--border-color-1);
					padding-bottom: 25px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 15px;
					.profile-icon {
						@include icon-outline;
						width: 27px;
						height: 27px;
					}

					.icon-item {
						display: flex;
						align-items: center;
					}

					.user-info_block {
						display: flex;
						flex-direction: column;
						flex-grow: 1;
						padding-left: 20px;
						.user_title {
							color: var(--inverted-bg-color);
							font-family: "Roboto";
							font-style: normal;
							font-weight: 400;
							font-size: 16px;
							line-height: 19px;
							margin-bottom: 0;
						}

						// .additional_info {

						// }

						.user_info {
							font-family: "Roboto";
							font-style: normal;
							font-weight: 400;
							font-size: 14px;
							line-height: 20px;
							color: var(--placeholder-color-1);
							margin: 0;
							position: absolute;
						}
					}

					.user-upload_icon {
						.arrowright_icon {
							@include icon-outline;
						}
					}
				}
			}

			.btn-next_block {
				width: 100%;
				margin-top: auto;
				margin-bottom: 60px;
				display: flex;
				justify-content: center;
				.btn-continue {
					width: 88%;
					background-color: var(--button-bg);
					color: #ffffff;
					border-radius: 12px;
					border: none;
				}
				.btn_disabled {
					@include btn-disabled;
				}
			}
		}

		.confirm_block {
			display: flex;
			align-self: center;
			width: 88%;
			margin-top: auto;
			margin-bottom: 60px;

			.confirm_btn {
				@include btn-width;
				background-color: var(--button-bg);
				color: #ffffff;
				border-radius: 12px;
				border: none;
				// padding: 16px 93px 16px 93px;
			}
		}
	}
}

@import "src/styles/mixins/mixin.scss";

.modal {
  font-family: "Roboto";
  z-index: 10001;

  .modalalert-section {
    padding: 12px 12px 0;
    @include modal-active;
    margin: 0 10px;
    border: none;
    border-radius: 28px;

    width: 95%;
    z-index: 2001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      margin: 0;
    }
    .check-icon_block {
      margin-top: 27px;
      border: 2px solid var(--inverted-bg-color);
      border-radius: 50%;
      width: 33px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      .check_icon {
        color: var(--inverted-bg-color);
        font-size: 18px;
      }
    }

    .alert-text {
      text-align: center;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 23px;
      line-height: 30px;
      color: var(--modal-main-text);
    }
    .alert-decs {
      margin-top: 10px;
      color: var(--modal-main-text);
      font-size: 16px;
      text-align: center;
    }

    .alert-buttons {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 10px;
      padding: 30px 0;
      button {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border: none;
        background: inherit;
        color: var(--text-color-1);
      }
    }
  }
}

.userSupportInfoWrap {
  display: flex;
  flex-direction: column;
  padding: 10px 0 80px;

  th,
  td {
    border: 1px solid var(--secondary-border-color);
  }
  th {
    &:last-child {
      border: 1px solid var(--secondary-border-color);
    }
  }
}

.userSupportInfoContainer {
  [class~="MuiTableCell-root"] {
    color: var(--inverted-bg-color);
    background-color: var(--main-bg-color);
  }
}

.userSupportInfoRowCell {
  max-width: 100px;
  word-wrap: break-word;
}

.userSupportInfoSendButton {
  background-color: var(--main-bg-color);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

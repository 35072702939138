@import "reset";
@import "themes/dark";
@import "themes/light";
@import "colors/colors";
@import "mixins/mixin";
@import "variables/global";

[data-theme="dark"] {
  height: 100%;
  width: 100%;
  color: rgb(229, 229, 229);
  transition:
    background-color 0.3s,
    color 0.3s;
}

[data-theme="light"] {
  height: 100%;
  width: 100%;
  transition:
    background-color 0.3s,
    color 0.3s;
}

html,
body {
  min-height: 100vh;
  min-height: 100%;
  font-family: "Roboto";
  font-style: normal;
}

body {
  overflow-x: hidden;
  margin: 0px;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotoregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

* {
  text-size-adjust: none !important;
}

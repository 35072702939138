@import "src/styles/mixins/mixin.scss";

.modal-action {
  @include modal;
  z-index: 10001;

  .modalaction-section {
    @include modal-active;

    border: none;
    position: absolute;
    border-radius: 28px;
    top: 30;
    width: 95%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .block-guide {
      display: flex;
      flex-direction: column;
      align-items: center;
      .guide-title_block {
        .guide_info {
          text-align: left;
          padding: 5px 17px 13px 25px;
          font-size: 14px;
          color: var(--inverted-bg-color);
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          display: flex;
          align-items: center;
          letter-spacing: 0.5px;
        }
      }

      .guide_img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 90%;
          height: auto;
        }
      }
    }

    p {
      margin: 0;
    }

    .alert-text {
      text-align: center;
      padding: 24px 10px 0 10px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: var(--modal-main-text);
    }

    .alert-info_text {
      padding-top: 8px;
      padding-bottom: 20px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-color);
    }

    .action-block {
      margin-right: 20px;
      max-width: 80%;
      display: flex;
      align-items: center;
      @include border;

      button {
        border: none;
        background: inherit;
        outline: none;
        display: flex;
      }
      .border_bottom {
        padding: 19px 0;
        color: var(--text-color-1);
      }
      .btn-modal_handler {
        padding-left: 10px;
        // width: 268px;
        .action_handler-one {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
        }
        .action_handler-two {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.5px;
        }
      }

      .icon-modal_active {
        margin-top: 3px;
        svg {
          width: 25px;
          height: 25px;
        }
        path {
          stroke: var(--inverted-bg-color);
          fill: var(--inverted-bg-color);
        }
      }
    }

    .alert-buttons {
      padding: 34px 0;
      display: flex;
      justify-content: center;
      button {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        border: none;
        background: inherit;
        color: var(--text-color-1);
      }
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.historyorder-section {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color-secondary);
  color: var(--inverted-bg-color);
  font-family: "Roboto";
  font-style: normal;
  padding: 0px 0px 10px 0px;
  overflow-y: scroll;
  box-sizing: border-box;

  // .top-nav {
  // 	display: flex;
  //     align-items: center;
  //     @include main-bg;
  //     @include top-nav-paddings;
  //     color: #fff;
  a {
    display: flex;
    .ArrowLeft {
      @include icon-outline;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 30px;
    }
  }
  .top-nav-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
    display: flex;
    flex-direction: row;
    align-items: center;
    letter-spacing: 0.1px;
    padding: 0px 16px;
    .copy-to-clipboard {
      width: 32px;
      height: 32px;
      display: inline-flex;
      background-color: var(--main-bg-color);
      border-radius: 100%;
      border: none;
      align-items: center;
      justify-content: center;
      path {
        fill: var(--inverted-bg-color);
        stroke: var(--inverted-bg-color);
      }
    }
  }
  // }
  .order-form {
    display: flex;
    flex-direction: column;

    .map-adress {
      border-radius: 30px 30px 16px 16px;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      height: 215px;
      .map-display {
        width: 100%;
      }
    }

    @include text-font-size;
    .order-date {
      margin-top: 24px;
      margin-left: 16px;
      font-size: 16px;
      color: var(--inverted-bg-color);
      padding-bottom: 8px;
    }
    .header-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 10px 20px 0px 20px;
      .payment-type {
        display: flex;
        align-items: center;
        .payment-icon {
          @include icon-size;
          margin-right: 10px;
        }
        .money {
          color: green;
        }
        .card {
          color: #4c5937;
        }
        .bonus {
          color: #fea523;
          margin-left: 10px;
        }
      }
    }
    .bot-line {
      border: 1px solid grey;
      margin: 0px 10px 10px 10px;
    }
    .profile-info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 12px 0px 12px 0px;
      .profile-img {
        @include user-img-size;
        border: 3px solid #fea523;
        border-radius: 50%;
      }
      .customer-name {
        margin-left: 10px;
        align-self: center;
      }
    }
    .trip-info {
      margin: 5px 16px 0px 16px;
      display: flex;

      justify-content: space-between;
      .address-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 16px;
        gap: 6px;
        margin: 0px;
        border-radius: 16px;
        background-color: var(--main-bg-color);
        color: var(--order-detail-address-text);
        .address {
          display: flex;
          flex-direction: row;
          .icon-item {
            margin-right: 16px;
            margin-top: 4px;
            .path1 {
              fill: var(--inverted-bg-color);
            }
            .path2 {
              fill: var(--main-bg-color);
            }
            .map-circle-color {
              stroke: var(--order-detail-address-text);
              fill: var(--main-bg-color);
            }
          }
          .address-text {
            font-weight: 700;
            font-size: 18px;
            padding-bottom: 9px;
            margin: 0px 0px 3px 0px;
            border-bottom: 1.5px solid var(--border-color-1);
          }
          .address-without-border {
            font-weight: 700;
            font-size: 18px;
            padding-bottom: 0px;
            margin: 0px 0px 3px 0px;
            border: none;
          }
          .FaAngleDown {
            align-self: center;
            @include text-col;
            @include icon-size;
          }
        }
      }
    }
    .trip-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      gap: 8px;
      margin: 5px 16px 0px 16px;
      border-radius: 16px;
      background-color: var(--main-bg-color);
      color: var(--order-detail-address-text);
      .label-info {
        text-align: right;
        font-size: 18px;
        font-weight: 700;
        .units-text {
          font-size: 14px;
        }
      }
      .label-text {
        font-size: 16px;
      }
      .profile-img {
        width: 22px;
        height: 22px;
        border-radius: 100px;
      }
      &-client {
        @extend .trip-item;
        justify-content: normal;
      }
      .icon-container {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        .icon-item-svg {
          width: 22px;
          height: 22px;
          path {
            fill: var(--inverted-bg-color);
          }
        }
        .icon-item-svg-yellow {
          width: 22px;
          height: 22px;
          path {
            fill: #ffde17;
          }
        }
      }
    }
    .trip-details {
      display: flex;
      flex-direction: column;
      margin: 0px 20px 0px 20px;
      p {
        margin: 0px;
      }
      .details-line {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        .govnumber {
          text-transform: uppercase;
          margin-left: 20px;
        }
        .pref-item {
          margin-right: 5px;
        }
      }
      .set-client-reting {
        align-self: center;
        margin-top: 15px;
        @include text-font-size;
      }
      .rider-reting {
        list-style-type: none;
        padding: 0px;
        display: flex;
        justify-content: center;
        .reting-item {
          .star {
            height: 11vw;
            width: 11vw;
          }
          .yellow {
            color: yellow;
          }
        }
      }
      .set-client-note {
        align-self: center;
        margin: 0px;
        @include text-font-size;
      }
      .comment {
        width: 80%;
        height: 50px;
        border: 2px solid #000;
        align-self: center;
        margin-bottom: 30px;
        border: 2px solid #fea523;
      }
      .finish-btn {
        padding: 10px 15px 10px 15px;
        @include main-bg;
        color: #fff;
        @include btn-bb;
        //border: none;
        margin-bottom: 20px;
        align-self: center;
        @include text-font-size;
        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}

@import "src/styles/mixins/mixin.scss";

.modal {
    @include modal;
    .updpass-section {
        width: 80vw;
        z-index: 1000;
        background-color: #fff;
        @include main-font;
        display: flex;
        flex-direction: column;
        .updpass-text {
            margin: 25px 0px 0px 25px;
            @include nav-font-size;
        }
        .input-block {
            display: flex;
            flex-direction: column;
            margin: 0px 20px 0px 20px;
            align-items: center;
            p {
                margin: 0px;
            }
            .label-info {
                width: 100%;
                color: #000;
                font-weight: 300;
                margin-top: 20px;
                border: none;
                @include border-bot-col;
                padding-bottom: 5px;
                @include text-font-size;
            }
            .error {
                color: red;
                @include error-font-size;
            }
        }
        .updpass-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            p {
                @include text-font-size;
                margin-right: 20px;
                text-transform: uppercase;
            }
        }
    }
}
@import "src/styles/mixins/mixin.scss";

.main-section {
	min-height: 100vh;
	background-color: var(--main-bg-color);

	.container {
		@include container;
		.auth-section__main {
			#error {
				margin-left: 110px;
				margin-top: 2px;
				@include error-text;
			}

			.auth_icon {
				display: flex;
				align-items: center;
				margin-top: 10px;

				.back-icon {
					@include icon-outline;
				}

				.close-icon {
					@include icon-outline;
				}
			}

			.auth-section__login {
				@include indent-top;
				.login_title {
					text-align: start;
					width: 248px;

					.login-title__auth {
						margin: 0;
						font-family: "Roboto";
						font-style: normal;
						font-weight: 700;
						font-size: 24px;
						line-height: 30px;
						color: var(--inverted-bg-color);
					}

					.login-title__info {
						font-family: "Roboto";
						margin-top: 8px;
						font-style: normal;
						font-weight: 400;
						font-size: 14px;
						line-height: 20px;
						color: var(--text-color);
					}
				}
			}

			.auth_phone-block {
				display: flex;
				align-items: center;
				margin-top: 25px;
				.auth_phone {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 13px 14px 8px 14px;
					border-radius: 12px;
					background-color: var(--main-bg-color-secondary);

					.auth-icon_flag {
						padding-right: 6px;
					}

					.number_country {
						font-family: "Roboto";
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 19px;
						color: var(--text-color-1);
					}
				}

			}

			.auth-btn_continue_block {
				margin: 72px 15px 0 15px;
				display: flex;
				justify-content: center;

				.continue_btn {
					@include btn-continue;
					@include btn-width;
				}

				.btn_disabled {
					color: var(--button-text-color-disabled);
					background-color: var(--text-disable-color);
				}
			}

			.footer-info {
				text-align: center;
				margin-top: 210px;
				padding-bottom: 136px;
				p {
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					color: var(--text-color);
					padding: 0;
					margin: 0;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					padding-bottom: 6px;
				}

				.link_register {
					text-decoration: underline;
					font-family: "Roboto";
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 16px;
					color: var(--inverted-bg-color);
				}
			}
		}
	}
}

@import "src/styles/mixins/mixin.scss";
@import "../../styles/themes/dark.scss";
@import "../../styles/themes/light.scss";

.bottom-menu {
  @include main-font;
  display: flex;
  background-color: var(--main-bg-color);
  color: var(--text-color);
  justify-content: space-around;
  align-items: center;
  bottom: 0px;
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 10;

  .menu-item {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    & .MuiBadge-anchorOriginTopRightRectangle {
      top: 3px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
    }
    & .MuiBadge-badge {
      height: 16px;
      min-width: 17px;
      padding: 0;
    }
  }
  & .active {
    .menu-item-icon {
      path {
        fill: var(--informing-bg-1);
        stroke: var(--informing-bg-1);
      }
    }
    // color:var(--informing-bg-1);
  }
  a {
    all: unset;
  }
  .menu-item-icon {
    width: 22px;
    height: 22px;
    margin-bottom: 20px;

    path {
      stroke-width: 0;
      fill: var(--icon-color-1);
      stroke: var(--icon-color-1);
    }
  }

  .trevels-icon {
    path:last-child {
      fill: var(--main-bg-color-secondary);
      stroke: var(--main-bg-color-secondary);
    }
  }

  .menu-item-title {
    position: fixed;
    bottom: 10px;
  }
  .menu-item .active,
  a.active,
  a:active,
  a:focus,
  div:focus {
    color: var(--informing-bg-1);
  }
}

.isDisabled {
  pointer-events: none;
  opacity: 0.8;
}

.menu-main-container {
  @include main-font;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  gap: 8px;
  width: 100%;
  height: calc(100% - 80px);
  top: 0;
  position: fixed;
  background-color: var(--main-bg-color);
  z-index: 100;
  overflow-y: scroll;
  box-sizing: border-box;

  .profile-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    padding-top: 2px;

    .profile-section {
      display: flex;
      flex-direction: column;
      background: #ffde17;
      border-radius: 28px;
      height: auto;
      width: 100%;
      padding: 24px 0 26px 0;
      .profile-img-block {
        display: flex;
        justify-content: center;
        .profile-img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .profile-info {
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #212121;
        justify-content: center;
        .label-info {
          width: 100%;
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          display: flex;
          align-items: center;
          letter-spacing: 0.1px;
          justify-content: center;
        }
      }
      .label-edit {
        width: 104px;
        height: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.25px;
        color: #616161;
      }
    }
    .profile-details {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 100%;
      gap: 6px;
      padding-top: 8px;
      .profile-detail-item {
        width: 33%;
        height: 88px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        letter-spacing: 0.25px;
        color: var(--text-color-1);
        border-radius: 16px;
        background-color: var(--main-bg-color-secondary);
        align-self: stretch;
        .label-prof {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          padding-left: 14px;
          padding-bottom: 4px;
        }
        .value-prof {
          font-weight: 700;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.5px;
          padding-left: 14px;
        }
        @media screen and (max-width: 330px) {
          .label-prof {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            padding-left: 6px;
            padding-bottom: 4px;
          }
          .value-prof {
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            letter-spacing: 0.5px;
            padding-left: 6px;
          }
        }
      }
    }
  }
  .label {
    width: 248px;
    height: 24px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: var(--text-color-1);
    padding-top: 34px;
  }
  .menu-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    width: 100%;
    min-height: 54px;
    border-bottom: 1px solid var(--main-bg-color-secondary);
    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      align-items: center;
      letter-spacing: 0.5px;
      color: var(--text-color-1);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .themelabel {
        color: var(--placeholder-color-1);
        font-size: 14px;
        line-height: 20px;
      }
    }
    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      width: 26px;
      height: 26px;
      path {
        fill: var(--inverted-bg-color);
        stroke: var(--inverted-bg-color);
        stroke-width: 0;
        // &:last-child {
        //   stroke: var(--inverted-bg-color);
        // }
      }
    }

    .default-icon {
      width: 26px;
      height: 26px;
      path {
        stroke: var(--inverted-bg-color);
      }
    }

    .stroke-default-icon {
      width: 26px;
      height: 26px;
      path {
        fill: var(--inverted-bg-color);
      }
    }

    .WalletIcon {
      path {
        &:last-child {
          stroke: var(--inverted-bg-color);
          stroke-width: 2px;
        }
      }
    }
    .star-icon {
      path {
        stroke-width: 1.5;
        stroke: var(--inverted-bg-color);
        fill: var(--main-bg-color);
      }
    }
    .arrow {
      .arrow-icon {
        stroke-width: 1;
        @include icon-outline;
      }
      margin-left: auto;
    }
  }
  a {
    all: unset;
  }
}

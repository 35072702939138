.orderTime {
  display: flex;
  p {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  span:nth-child(2) {
    font-weight: 700;
  }
}

.arrowRight {
  path {
    fill: none;
    stroke: var(--inverted-bg-color);
  }
}

.addressTextBlock {
  .mainText {
    font-size: 18px;
    font-weight: 700;
		padding-bottom: 3px;
  }

  .secondaryText {
    font-size: 14px;
    color: var(--text-color);
  }
}

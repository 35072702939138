@import "src/styles/mixins/mixin.scss";

.cardsListBlock {
  display: flex;
  flex-direction: column;

  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  .navCard {
    @include border;
    padding: 11px 0;
    .cardsSection {
      display: flex;
      align-items: center;
      .radioBtnBlock {
        .radioCheckBlock {
          .radioLabel {
            margin-left: -11px;
            margin-right: 5px;

            .radioBtn {
              color: var(--inverted-bg-color);
            }
          }

          // & .MuiFormGroup-root {
          //   flex-direction: row;
          //   align-items: center;
          // }
        }
      }
    }

    .cardsItems {
      display: flex;
      align-items: center;
      column-gap: 10px;
      width: 100%;

      .cardTitle {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.5px;
      }

      .selectedCard {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #1db960;
        padding: 4px 8px;
        background: rgba(29, 185, 96, 0.2);
        border-radius: 100px;
      }

      .trashIcon {
        margin: 0 0 0 auto;
        path {
          fill: var(--informing-bg-3);
        }
      }
    }
  }
}

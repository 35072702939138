@import "src/styles/mixins/mixin.scss";

.top-content_radius {
  display: flex;
  align-items: center;
  justify-content: center;

  .plusIcon,
  .minusIcon {
    padding: 2px;
    height: 22px;
    path {
      stroke: #000;
    }
  }

  .plusIcon {
    width: 23px;
  }

  .btn_disabled {
    path {
      stroke: #abaaaa; //TODO
    }
  }

  .value-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 9px;
    width: 36px;

    .value-radius {
      color: #000;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 17px;
      padding-right: 1px;
    }

    .value-unit {
      color: #000;
      font-family: "Roboto";
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0.1px;
      padding-top: 2px;
    }
  }
}
